import { createStore } from 'vuex'
import Cookie from 'cookie-universal'
const cookie = Cookie()
import axios from 'axios';
import { notify } from "@kyvg/vue3-notification";
export const baseURL = process.env.VUE_APP_BASE_URL;

export default createStore({
  state: {
    loader: true,
    Spinner: false,
    baseURL: baseURL || 'https://dev.etmaam.com.sa',
    Userdata: cookie.get('Userdata') ? cookie.get('Userdata') : '',
    Companydata: cookie.get('Companydata') ? cookie.get('Companydata') : '',
    Token: cookie.get('Token') ? cookie.get('Token') : '',
    CompanyToken: cookie.get('CompanyToken') ? cookie.get('CompanyToken') : '',

    locales: cookie.get('lang') ? cookie.get('lang') : 'ar',
    // Packages
    packageOrder: {
      step1: false,
      step2: {
        status: false,
        NumberEmployees: 0,
        AdditionalRecord: 0,
        paymenytPlanType: '',
        total: 0,
        totalAmount: 0,
        atrrbuites: []
      },
      step3: {
        status: false,
        ProviderId: 1,
        BranchId: null,
      },
      step4: {
        status: false,
        CompanyId: null,
      },
      step5: {
        coupon_id: null,
        payment_method_id: null,
        user_name: null,
        Adapter: null,
        Bank_id: null,
        Notes: null,
        ReceiptFiles: [],
        terms: false
      },
    },
    serviceOrder: {
      step1: {
        status: false,
        ProviderId: 1,
        BranchId: null,
      },
      step2: {
        status: false,
        CompanyId: null,
        requester_type: null,

      },
      step3: {
        status: false,
        atrrbuites: []
      },
      step4: {
        status: false,
        payment_method_id: null,
        coupon_id: null,
        user_name: null,
        Adapter: null,
        Bank_id: null,
        Notes: null,
        ReceiptFiles: [],
        terms: false
      },
    },
    CompanySelected: [],
    showRate: false,
    updateComment: false,
    VerfyLogin: false,
    // StaticContent
    StaticContent: [],
    StaticHeroSection: [],
    StaticFooter: [],
    StaticJoinUs: [],
    // Services
    Services_Type: [],
    // Services
    Blogs: [],
    BlogsDetails: {},
    BlogsCategories: [],
    BlogComments: [],
    pagesCount: 0,
    // Partners
    Partners: [],
    // Testimonials
    Testimonials: [],

    BankAccounts: [],

    JobsCategories: [],
    JobCategoryData: [],
    JobData: [],

    DownloadsCenterCatg: [],
    DownloadCenterData: [],

    FaqsCatgs: [],
    FaqsData: [],

    partnershipsTypes: [],
    cities: [],
    roles: [],
    Companies: [],

    MainCategSerives: [],
    SubCategSerives: [],
    ServicesCard: [],
    Service: [],
    ServiceData: [],
    ServiceOrders: [],
    PackageOrders: [],
    ServiceOrderDetails: {},

    PackagesCateg: [],
    PackagesCards: [],
    PackagesDetails: [],
    PackageOrderDetails: {},

    ContactReasons: [],
    isLike: false,
    commercialData: [],
    EtmaamBranches: [],

    Tickets: [],
    TicketDetails: {}

  },
  mutations: {
    // Other mutations as needed...
    RESET_ORDER(state) {
      state.packageOrder = {
        step1: false,
        step2: {
          status: false,
          NumberEmployees: 0,
          AdditionalRecord: 0,
          paymenytPlanType: '',
          total: 0,
          totalAmount: 0,
          atrrbuites: []
        },
        step3: {
          status: false,
          ProviderId: 1,
          BranchId: null,
        },
        step4: {
          status: false,
          CompanyId: null,
        },
        step5: {
          coupon_id: null,
          payment_method_id: null,
          user_name: null,
          Adapter: null,
          Bank_id: null,
          Notes: null,
          ReceiptFiles: [],
          terms: false
        },
      };
      state.serviceOrder = {
        step1: {
          status: false,
          ProviderId: 1,
          BranchId: null,
        },
        step2: {
          status: false,
          CompanyId: null,
          requester_type: null,
        },
        step3: {
          status: false,
          atrrbuites: []
        },
        step4: {
          status: false,
          payment_method_id: null,
          coupon_id: null,
          user_name: null,
          Adapter: null,
          Bank_id: null,
          Notes: null,
          ReceiptFiles: [],
          terms: false
        },
      };
    },
    // *********************** SET_Tickets***********************
    SET_Tickets(state, data) {
      state.Tickets = data
    },
    SET_TicketDetails(state, data) {
      state.TicketDetails = data
    },
    // *********************** SET_LIKE ***********************
    SET_LIKE(state, boolean) {
      state.isLike = boolean
    },
    SET_Loader(state, boolean) {
      state.loader = boolean;
    },
    SET_Spinner(state, boolean) {
      state.Spinner = boolean;
    },
    // *********************** SET Atrrbuites ***********************
    SET_AtrrbuitesPack(state, obj) {
      state.packageOrder.step2.atrrbuites.push(obj);
    },
    SET_AtrrbuitesServ(state, obj) {
      state.serviceOrder.step3.atrrbuites.push(obj);
    },
    // *********************** SET Commercial ***********************
    SET_Commercial(state, data) {
      state.commercialData = data
    },
    // *********************** SET packages ***********************
    SET_PACKAGESDetails(state, data) {
      state.PackagesDetails = data
    },
    SET_PACKAGES(state, data) {
      state.PackagesCards = data
    },
    SET_PackagesCateg(state, data) {
      state.PackagesCateg = data
    },
    SET_PackageOrderDetails(state, data) {
      state.PackageOrderDetails = data
    },
    // *********************** SET Services ***********************
    SET_ServiceData(state, data) {
      state.ServiceData = data
    },
    SET_ServicesCard(state, data) {
      state.ServicesCard = data
    },
    SET_Service(state, data) {
      state.Service = data
    },
    SET_SubCateg(state, data) {
      state.SubCategSerives = data
    },
    SET_MainCateg(state, data) {
      state.MainCategSerives = data
    },
    // *********************** SET Join us data ***********************
    SET_cities(state, data) {
      state.cities = data
    },
    SET_roles(state, data) {
      state.roles = data
    },
    SET_partnershipsTypes(state, data) {
      state.partnershipsTypes = data
    },
    // *********************** SET Contact Reasons ***********************
    SET_ContactReasons(state, data) {
      state.ContactReasons = data;
    },
    // *********************** SET StaticContent ***********************
    SET_StaticContent(state, data) {
      state.StaticContent = data;
    },
    SET_StaticHeroSection(state, data) {
      state.StaticHeroSection = data;
    },
    SET_StaticFooter(state, data) {
      state.StaticFooter = data;
    },
    SET_StaticJoinUs(state, data) {
      state.StaticJoinUs = data;
    },

    SET_FaqsData(state, data) {
      state.FaqsData = data;
    },
    SET_FaqsCatgs(state, data) {
      state.FaqsCatgs = data;
    },
    // *********************** SET DownloadsCenterData ***********************
    SET_DownloadsCenterData(state, data) {
      state.DownloadCenterData = data;
    },
    // *********************** SET DownloadsCenterCatg ***********************
    SET_DownloadsCenterCatg(state, data) {
      state.DownloadsCenterCatg = data;
    },
    // *********************** SET DownloadsCenter ***********************
    SET_DownloadsCenter(state, data) {
      state.DownloadsCenter = data;
    },
    SET_JobData(state, data) {
      state.JobData = data;
    },
    // *********************** SET JobCategoryData ***********************
    SET_JobCategoryData(state, data) {
      state.JobCategoryData = data;
    },
    // *********************** SET JobsCategories ***********************
    SET_JobsCategories(state, data) {
      state.JobsCategories = data;
    },
    // *********************** SET BankAccounts ***********************
    SET_BankAccounts(state, data) {
      state.BankAccounts = data;
    },
    // *********************** SET Token ***********************
    SET_Token(state, data) {
      cookie.set('Userdata', data.user);
      cookie.set('Companydata', data.company);
      cookie.set('Token', data.token);
      state.Userdata = data.user;
      state.Companydata = data.company;
      state.Token = data.token;
    },
    SET_CompanyToken(state, data) {
      cookie.set('CompanyToken', data.token);
      cookie.set('Userdata', data.user);
      cookie.set('Companydata', data.company);
      state.CompanyToken = data.token;
      state.Userdata = data.user;
      state.Companydata = data.company;
    },
    // *********************** SET LANG ***********************
    SET_LANG(state, locale) {
      state.locales = locale
      cookie.set('lang', locale);
    },
    // *********************** SET Services Type ***********************
    SET_Services(state, data) {
      state.Services_Type = data;
    },

    SET_ServiceOrders(state, data) {
      state.ServiceOrders = data;
    },
    SET_PackageOrders(state, data) {
      state.PackageOrders = data;
    },
    SET_ServiceOrderDetails(state, data) {
      state.ServiceOrderDetails = data;
    },

    // *********************** SET Blogs ***********************
    SET_pagesCount(state, data) {
      state.pagesCount = data;
    },
    SET_Blogs(state, data) {
      state.Blogs = data;
    },
    SET_BlogsDetails(state, data) {
      state.BlogsDetails = data;
    },
    SET_BlogsCategories(state, data) {
      state.BlogsCategories = data;
    },
    SET_BlogComments(state, data) {
      state.BlogComments = data;
    },
    // *********************** SET Partners ***********************
    SET_Partners(state, data) {
      state.Partners = data;
    },
    // *********************** SET Testimonials ***********************
    SET_Testimonials(state, data) {
      state.Testimonials = data;
    },
    // *********************** SET Testimonials ***********************
    SET_Companies(state, data) {
      state.Companies = data;
    },
    SET_EtmaamBranches(state, data) {
      state.EtmaamBranches = data;
    },
  },
  actions: {
    GetTickets({ commit }) {
      return axios
        .get(`/company/dashboard/tickets`, {
          headers: {
            "Authorization": `Bearer ${cookie.get('CompanyToken')}`
          }
        })
        .then((res) => {
          let Tickets = res.data.data.Ticket;
          commit('SET_Tickets', Tickets);
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    GetTicketDetails({ commit }, id) {
      return axios
        .get(`/company/dashboard/tickets/${id}`, {
          headers: {
            "Authorization": `Bearer ${cookie.get('CompanyToken')}`
          }
        })
        .then((res) => {
          let Tickets = res.data.data;
          commit('SET_TicketDetails', Tickets);
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    // *********************** SET LOADER  ***********************
    SETLOADER({ commit }, boolean) {
      commit('SET_Loader', boolean);
    },
    SETSpinner({ commit }, boolean) {
      commit('SET_Spinner', boolean);
    },
    // *********************** SET Companies  ***********************
    GetEtmaamBranches({ commit }, packagesId, providerId) {
      return axios
        .get(`/user/branches/offer-packages/${packagesId}?service_provider_id=${providerId}`, {
          headers: {
            "Authorization": `Bearer ${cookie.get('Token')}`
          }
        })
        .then((res) => {
          let EtmaamBranches = res.data.data;
          commit('SET_EtmaamBranches', EtmaamBranches);
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    GetCompanies({ commit }) {
      commit('SET_Loader', true);
      return axios
        .get(`/user/companies`, {
          headers: {
            "Authorization": `Bearer ${cookie.get('Token')}`
          }
        })
        .then((res) => {
          let Companies = res.data.data;
          commit('SET_Companies', Companies);
          commit('SET_Loader', false);
        })
        .catch(function (error) {
          commit('SET_Loader', false);
          console.log('Error: ', error);
        });
    },
    // *********************** SET Packages  ***********************
    PackagesDetails({ commit }, id) {
      return axios
        .get(`/user/packages/${id}`,)
        .then((res) => {
          let PackagesDetails = res.data.data;
          commit('SET_PACKAGESDetails', PackagesDetails);
          PackagesDetails.attributes.forEach(item => {
            if (item.type == 'checkbox') {
              commit('SET_AtrrbuitesPack', { attribute_id: item.id, attribute_values_ids: [] });
            }
            else if (item.type == 'select') {
              commit('SET_AtrrbuitesPack', { attribute_id: item.id, attribute_value_id: null });
            }
            else if (item.type == 'text') {
              commit('SET_AtrrbuitesPack', { attribute_id: item.id, attribute_value: '' });
            }
            else if (item.type == 'file') {
              commit('SET_AtrrbuitesPack', { attribute_id: item.id, attribute_value: '' });
            }
            else if (item.type == 'date') {
              commit('SET_AtrrbuitesPack', { attribute_id: item.id, attribute_value: '' });
            }
          });
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },


    PackagesCards({ commit }, id) {
      commit('SET_Spinner', true);
      return axios
        .get(`/user/packages?package_category_id=${id}`)
        .then((res) => {
          if (res.status == 200) {
            commit('SET_Spinner', false);
            let packages = res.data.data['Package'];
            commit('SET_PACKAGES', packages);
          }
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },


    PackagesCateg({ commit }) {
      return axios
        .get(`user/package-categories`)
        .then((res) => {
          let PackagesCateg = res.data.data['PackageCategory'];

          commit('SET_PackagesCateg', PackagesCateg);
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },

    // *********************** Get Contact Reasons Data ***********************
    GetContactReasons({ commit }) {
      return axios
        .get(`user/contact-reasons`)
        .then((res) => {
          let ContactReasons = res.data.data.ContactReason;
          commit('SET_ContactReasons', ContactReasons);
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    // *********************** Get Static Content Data ***********************
    async GetStaticContent({ commit }) {
      commit('SET_Loader', true);
      return await axios
        .get(`user/static-content`)
        .then((res) => {
          if (res.status == 200) {
            let Content = res.data.data;
            commit('SET_StaticContent', Content);
          }
        })
        .catch(function (error) {
          console.log('Error: ', error);
        }).finally(function () {
          commit('SET_Loader', false);
        });
    },
    async GetStaticFooter({ commit }) {
      return await axios
        .get(`user/static-content?category=footer`)
        .then((res) => {
          let Content = res.data.data;
          commit('SET_StaticFooter', Content);
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    async GetStaticHeroSection({ commit }) {
      try {
        commit('SET_Loader', true);

        const response = await axios.get('user/static-content?category=hero_section');

        if (response.status === 200) {
          const content = response.data.data;
          commit('SET_StaticHeroSection', content);
        } else {
          console.error('Unexpected response status:', response.status);
        }
      } catch (error) {
        console.error('Error fetching static hero section:', error);
        // Optional: Dispatch or commit to handle global error state
        commit('SET_Error', 'Failed to fetch hero section data');
      } finally {
        commit('SET_Loader', false);
      }
    },

    GetSET_StaticJoinUs({ commit }) {
      return axios
        .get(`user/static-content?category=join_us_page`)
        .then((res) => {
          if (res.status == 200) {
            let Content = res.data.data;
            commit('SET_StaticJoinUs', Content);
          }
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },

    // *********************** Get Faq Data ***********************
    ServiceDetails({ commit }, id) {
      return axios
        .get(`/user/services/${id}`)
        .then((res) => {
          if (res.status == 200) {
            let serviceData = res.data.data;
            commit('SET_ServiceData', serviceData);
          }

        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    ServiceDetailsForOrder({ commit }, id) {
      commit('SET_Loader', true);
      return axios
        .get(`/user/services/${id}`)
        .then((res) => {
          let serviceData = res.data.data;
          commit('SET_ServiceData', serviceData);
          serviceData.attributes.forEach(item => {
            if (item.type == 'checkbox') {
              commit('SET_AtrrbuitesServ', { attribute_id: item.id, attribute_values_ids: [] });
            }
            else if (item.type == 'select') {
              commit('SET_AtrrbuitesServ', { attribute_id: item.id, attribute_value_id: null });
            }
            else if (item.type == 'text') {
              commit('SET_AtrrbuitesServ', { attribute_id: item.id, attribute_value: '' });
            }
            else if (item.type == 'file') {
              commit('SET_AtrrbuitesServ', { attribute_id: item.id, attribute_value: '' });
            }
            else if (item.type == 'date') {
              commit('SET_AtrrbuitesServ', { attribute_id: item.id, attribute_value: '' });
            }
          });
          commit('SET_Loader', false);

        })
        .catch(function (error) {
          commit('SET_Loader', false);
          console.log('Error: ', error);
        });
    },
    ServiceOrdersIndividial({ commit }) {
      return axios
        .get(`/user/my-service-requests`, {
          headers: {
            'Authorization': `Bearer ${cookie.get('Token')}`
          }
        })
        .then((res) => {
          if (res.status == 200) {
            let Orders = res.data.data.ServiceRequest;
            commit('SET_ServiceOrders', Orders);
            commit('SET_Loader', false);
          }
        })
        .catch(function (error) {
          commit('SET_Loader', false);
          console.log('Error: ', error);
        });
    },
    ServiceOrders({ commit }) {
      return axios
        .get(`/company/dashboard/service-requests`, {
          headers: {
            'Authorization': `Bearer ${cookie.get('CompanyToken')}`
          }
        })
        .then((res) => {
          if (res.status == 200) {
            let Orders = res.data.data.ServiceRequest;
            commit('SET_ServiceOrders', Orders);
            commit('SET_Loader', false);
          }
        })
        .catch(function (error) {
          commit('SET_Loader', false);
          console.log('Error: ', error);
        });
    },
    ServiceOrdersByStatus({ commit }, StatusId) {
      commit('SET_Loader', true);
      return axios
        .get(`/company/dashboard/service-requests?status_id=${StatusId}`, {
          headers: {
            'Authorization': `Bearer ${cookie.get('CompanyToken')}`
          }
        })
        .then((res) => {
          if (res.status == 200) {
            let Orders = res.data.data.ServiceRequest;
            commit('SET_ServiceOrders', Orders);
            commit('SET_Loader', false);
          }
        })
        .catch(function (error) {
          commit('SET_Loader', false);
          console.log('Error: ', error);
        });
    },

    ServiceOrderDetails({ commit }, id) {
      // commit('SET_Loader', true);
      return axios
        .get(`/user/service-requests/${id}`, {
          headers: {
            'Authorization': `Bearer ${cookie.get('Token')}`
          }
        })
        .then((res) => {
          if (res.status == 200) {
            let OrderDetails = res.data.data;
            commit('SET_ServiceOrderDetails', OrderDetails);
          }
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    PackageOrdersByStatus({ commit }, StatusId) {
      commit('SET_Loader', true);
      return axios
        .get(`/company/dashboard/package-requests?status_id=${StatusId}`, {
          headers: {
            'Authorization': `Bearer ${cookie.get('CompanyToken')}`
          }
        })
        .then((res) => {
          if (res.status == 200) {
            let Orders = res.data.data.PackageRequest;
            commit('SET_PackageOrders', Orders);
            commit('SET_Loader', false);
          }
        })
        .catch(function (error) {
          commit('SET_Loader', false);
          console.log('Error: ', error);
        });
    },
    PackageOrderDetails({ commit }, id) {
      return axios
        .get(`/company/dashboard/package-requests/${id}`, {
          headers: {
            'Authorization': `Bearer ${cookie.get('CompanyToken')}`
          }
        })
        .then((res) => {
          if (res.status == 200) {
            let OrderDetails = res.data.data;
            commit('SET_PackageOrderDetails', OrderDetails);
            commit('SET_Loader', false);
          }
        })
        .catch(function (error) {
          commit('SET_Loader', false);
          console.log('Error: ', error);
        });
    },


    ServicesCard({ commit }, id) {
      commit('SET_Spinner', true);
      return axios
        .get(`/user/services?category_id=${id}`, {
          headers: {
            'Authorization': `Bearer ${cookie.get('CompanyToken')}`
          }
        }).then((res) => {
          if (res.status == 200) {
            commit('SET_Spinner', false);
            let services = res.data.data['Service'];
            commit('SET_ServicesCard', services);
            commit('SET_Loader', false);
          }
        })
        .catch(function (error) {
          commit('SET_Loader', false);
          console.log('Error: ', error);
        });
    },
    ServicesCardCompany({ commit }, id) {
      commit('SET_Spinner', true);
      return axios
        .get(`/company/dashboard/services?category_id=${id}`, {
          headers: {
            'Authorization': `Bearer ${cookie.get('CompanyToken')}`
          }
        }).then((res) => {
          if (res.status == 200) {
            commit('SET_Spinner', false);
            let services = res.data.data['Service'];
            commit('SET_ServicesCard', services);
            commit('SET_Loader', false);
          }
        })
        .catch(function (error) {
          commit('SET_Loader', false);
          console.log('Error: ', error);
        });
    },
    Service({ commit }) {
      commit('SET_Spinner', true);
      return axios
        .get(`/company/dashboard/services`, {
          headers: {
            'Authorization': `Bearer ${cookie.get('CompanyToken')}`
          }
        })
        .then((res) => {
          if (res.status == 200) {
            commit('SET_Spinner', false);
            let services = res.data.data['Service'];
            commit('SET_Service', services);
            commit('SET_Loader', false);
          }
        })
        .catch(function (error) {
          commit('SET_Loader', false);
          console.log('Error: ', error);
        });
    },
    SubCatgsServices({ commit }, id) {
      commit('SET_Spinner', true);
      return axios
        .get(`user/sub-categories?category_id=${id}`)
        .then((res) => {
          let subCatg = res.data.data['SubCategory'];
          commit('SET_SubCateg', subCatg);

        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },

    MainCatgsServices({ commit }) {
      return axios
        .get(`user/main-categories`)
        .then((res) => {
          let MainCateg = res.data.data['MainCategory'];
          commit('SET_MainCateg', MainCateg);
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    GetFaqsData({ commit }, id) {
      commit('SET_Spinner', true);
      return axios
        .get(`/user/faqs?category_id=${id}`)
        .then((res) => {

          if (res.status == 200) {
            commit('SET_Spinner', false);
            let FaqsData = res.data.data['Faq'];
            commit('SET_FaqsData', FaqsData);
            commit('SET_Loader', false);
          }
        })

        .catch(function (error) {
          commit('SET_Loader', false);
          console.log('Error: ', error);
        });
    },


    GetFaqsCatgs({ commit }) {
      return axios
        .get(`/user/sub-categories?category_id=1`)
        .then((res) => {
          let FaqsCatgs = res.data.data['SubCategory'];
          commit('SET_FaqsCatgs', FaqsCatgs);
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },

    getCities({ commit }) {
      return axios
        .get(`/user/cities`)
        .then((res) => {
          let cities = res.data.data['City'];

          commit('SET_cities', cities);
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    getRoles({ commit }) {
      return axios
        .get(`/company/dashboard/roles`, {
          headers: {
            "Authorization": `Bearer ${cookie.get('CompanyToken')}`
          }
        })
        .then((res) => {
          let roles = res.data.data.Role;
          commit('SET_roles', roles);
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },

    partnershipsType({ commit }) {
      return axios
        .get(`/user/partnership-types`)
        .then((res) => {
          let Partner = res.data.data['PartnershipType'];
          commit('SET_partnershipsTypes', Partner);

        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },

    // *********************** Get Downloads Center Data ***********************
    DownloadsCenterData({ commit }, id) {
      return axios
        .get(`/user/documents?category_id=${id}`)
        .then((res) => {
          let downloadsCenterData = res.data.data['Document'];
          commit('SET_DownloadsCenterData', downloadsCenterData);
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    // *********************** Get Downloads Center Catg ***********************
    DownloadsCenterCategories({ commit }, id) {
      return axios
        .get(`user/document-categories`)
        .then((res) => {
          let downloadsCenterCatg = res.data.data['DocumentCategory'];
          commit('SET_DownloadsCenterCatg', downloadsCenterCatg);
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    // *********************** Get Job Data ***********************
    GetJobDetails({ commit }, id) {
      commit('SET_Spinner', true);
      return axios
        .get(`user/jobs/${id}`)
        .then((res) => {
          if (res.status == 200) {
            commit('SET_Spinner', false);
            let JobData = res.data.data;
            commit('SET_JobData', JobData);
            commit('SET_Loader', false);
          }
        })
        .catch(function (error) {
          commit('SET_Loader', false);
          console.log('Error: ', error);
        });
    },


    // *********************** Get Job Categories Data ***********************
    JobCategoriesData({ commit }, id) {
      commit('SET_Spinner', true);
      return axios
        .get(`user/jobs?category_id=${id}`)
        .then((res) => {
          commit('SET_Spinner', false);
          let JobCategoryData = res.data.data['Job'];
          commit('SET_JobCategoryData', JobCategoryData);
          commit('SET_Loader', false);
        })
        .catch(function (error) {
          commit('SET_Loader', false);
          console.log('Error: ', error);
        });
    },
    // *********************** Get Job Categories ***********************
    GetJobCategories({ commit }) {
      return axios
        .get(`user/job-categories`)
        .then((res) => {
          let JobsCategories = res.data.data['JobCategory'];
          commit('SET_JobsCategories', JobsCategories);
          commit('SET_Loader', false);

        })
        .catch(function (error) {
          commit('SET_Loader', false);
          console.log('Error: ', error);
        });
    },
    // *********************** Get Bank accounts ***********************
    GetBankAccounts({ commit }) {
      commit('SET_Loader', true);
      return axios
        .get(`user/bank-accounts`)
        .then((res) => {
          if (res.status == 200) {
            commit('SET_Loader', false);
            let BankAccounts = res.data.data['BankAccount'];
            commit('SET_BankAccounts', BankAccounts);
          }

        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    // *********************** Get Services Type ***********************
    GetServicesType({ commit }) {
      return axios
        .get(`/user/service-types`)
        .then((res) => {
          let ServicesType = res.data.data.ServiceType;
          commit('SET_Services', ServicesType);
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    // *********************** Get Blogs ***********************
    DeleteComment({ commit }, id) {
      let token = this.state.Token
      return axios
        .delete(`user/blogs/comments/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((res) => {
          notify({
            type: "success",
            title: "حذف تعليق",
            text: "تم حذف التعليق بنجاح",
          });
        })
        .catch(function (error) {
          notify({
            type: "error",
            title: "خـطـأ !!",
            text: error?.response?.data?.message,
          });
        });
    },
    ToggleLike({ commit }, id) {


      const token = this.state.Token;

      return axios
        .get(`user/blogs/${id}/toggle-like`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((res) => {
          console.log('res', res);
          if (res.status === 200) {
            // commit('SET_LIKE', true);
          } else {
            // commit('SET_LIKE', false);
          }
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });

    },
    GetBlogsComments({ commit }, payload) {
      return axios
        .get(`user/blogs/comments?blog_id=${payload.blogId}&page=${payload.page}`)
        .then((res) => {
          let BlogComments = res.data.data['Comment'];
          let pagesCount = res.data.data.pagesCount;
          commit('SET_BlogComments', BlogComments);
          commit('SET_pagesCount', pagesCount);
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    GetBlogs({ commit }) {
      return axios
        .get(`user/blogs`)
        .then((res) => {
          let blogs = res.data.data.Blog;
          commit('SET_Blogs', blogs);
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    GetBlogsDetails({ commit }, ID) {
      commit('SET_Loader', true);
      if (cookie.get('Token')) {
        const token = cookie.get('Token');
        return axios
          .get(`user/blogs/${ID}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
          .then((res) => {
            if (res.status == 200) {
              commit('SET_Loader', false);
              let BlogsDetails = res.data.data;
              commit('SET_BlogsDetails', BlogsDetails);
            }
          })
          .catch(function (error) {
            console.log('Error: ', error);
            commit('SET_Loader', false);
          });
      } else {
        return axios
          .get(`user/blogs/${ID}`)
          .then((res) => {
            if (res.status == 200) {
              commit('SET_Loader', false);
              let BlogsDetails = res.data.data;
              commit('SET_BlogsDetails', BlogsDetails);
            }
          })
          .catch(function (error) {
            console.log('Error: ', error);
            commit('SET_Loader', false);
          });
      }
    },
    GetBlogsSearch({ commit }, params) {
      commit('SET_Spinner', true);
      return axios
        .get(`user/blogs?search=${params}`)
        .then((res) => {
          if (res.status == 200) {
            commit('SET_Spinner', false);
            let blogs = res.data.data.Blog;
            commit('SET_Blogs', blogs);
          }
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    GetBlogsParams({ commit }, params) {
      commit('SET_Spinner', true);
      return axios
        .get(`user/blogs?category_id=${params}`)
        .then((res) => {
          if (res.status == 200) {
            commit('SET_Spinner', false);
            let blogs = res.data.data.Blog;
            commit('SET_Blogs', blogs);
          }
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    // *********************** Get Blogs Categories  ***********************
    GetBlogsCategories({ commit }) {
      commit('SET_Loader', true);
      return axios
        .get(`user/blog-categories`)
        .then((res) => {
          if (res.status == 200) {
            commit('SET_Loader', false);
            let categories = res.data.data.BlogCategory;
            commit('SET_BlogsCategories', categories);
          }
        })
        .catch(function (error) {
          console.log('Error: ', error);
          commit('SET_Loader', false);
        });
    },
    // *********************** Get Partners ***********************
    GetPartners({ commit }) {
      return axios
        .get(`user/partners`)
        .then((res) => {
          let partners = res.data.data.Partner;
          commit('SET_Partners', partners);
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    // *********************** Get Testimonials ***********************
    GetTestimonials({ commit }) {
      return axios
        .get(`user/customer-reviews`)
        .then((res) => {
          let testimonialt = res.data.data.CustomerReview;
          commit('SET_Testimonials', testimonialt);
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },

  },
})
