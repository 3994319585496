<template>
  <div class="hero">
    <teleport to="head">
      <link v-if="image" rel="preload" as="image" :href="image"/>
      <!-- <link rel="preload" href="https://fonts.googleapis.com/css2?family=Tajawal:wght@400;700&display=swap" as="style" onload="this.onload=null;this.rel='stylesheet'">
      <noscript><link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Tajawal:wght@400;700&display=swap"></noscript> -->
    </teleport>

    <img :src="image" class="cover"
         alt="Beautiful Hero Section Background"
         accept="image/webp"
         decoding="async"
         loading="eager"
         width="1440"
         height="704"/>

    <div class="container padding-heder info" >
      <h1 data-aos="fade-up">{{ main_title }}</h1>
      <h2 data-aos="fade-up">{{ secondary_title }}</h2>
      <p data-aos="fade-up">{{ hero_description }}</p>
      <router-link data-aos="fade-up" to="/about" class="btn-main secondary">
        {{ $t('Learn More') }}
      </router-link>
    </div>

    <div class="container-fluid info">
      <div class="row">
        <div class="col-md-2"></div>
        <div
          :class="[
            'guide',
            'col-md-10',
            { 'animate__animated': isHovered, 'animate__pulse': isHovered },
            $i18n.locale === 'ar' ? 'guide-ar' : 'guide-en'
          ]"
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
        >
          <div class="row">
            <div
              class="col-md-4 item"
              v-for="(card, index) in heroCards"
              :key="index"
            >
              <img
                :src="card.photo"
                alt="hero card"
                loading="lazy"
                style="width: 50px; height: 50px;"
              />
              <div>
                <h2 class="title">{{ card.title }}</h2>
                <p class="disc">{{ card.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isHovered: false,
      image: '',
      main_title: '',
      secondary_title: '',
      hero_description: '',
      heroCards: [],
    }
  },
  methods: {
    handleMouseEnter () {
      this.isHovered = true
    },
    handleMouseLeave () {
      this.isHovered = false
    },
    handleStaticHero (StaticContent) {
      const contentMap = {
        hero_main_photo: 'image',
        main_title: 'main_title',
        secoundary_title: 'secondary_title',
        hero_description: 'hero_description',
      }

      StaticContent.forEach((item) => {
        if (contentMap[item.key]) {
          this[contentMap[item.key]] = item.value
        }
      })

      this.heroCards = [
        {
          photo: StaticContent.find((item) => item.key === 'hero_card_1_photo')?.value || '',
          title: StaticContent.find((item) => item.key === 'hero_card_1_title')?.value || '',
          description: StaticContent.find((item) => item.key === 'hero_card_1_description')?.value || '',
        },
        {
          photo: StaticContent.find((item) => item.key === 'hero_card_2_photo')?.value || '',
          title: StaticContent.find((item) => item.key === 'hero_card_2_title')?.value || '',
          description: StaticContent.find((item) => item.key === 'hero_card_2_description')?.value || '',
        },
        {
          photo: StaticContent.find((item) => item.key === 'hero_card_3_photo')?.value || '',
          title: StaticContent.find((item) => item.key === 'hero_card_3_title')?.value || '',
          description: StaticContent.find((item) => item.key === 'hero_card_3_description')?.value || '',
        },
      ]
    },
  },
  mounted () {
    this.$store.dispatch('GetStaticHeroSection').then(() => {
      this.handleStaticHero(this.$store.state.StaticHeroSection)
    })
  },
}
</script>
<style lang="scss" scoped>
.hero {
  padding-top: 180px;
  background: linear-gradient(180deg, #0a3041 0%, #225476 100%);
  background-size: cover;
  border-radius: 0 0 40px 40px;
  overflow: hidden;
  position: relative;
  z-index: 0;



@media (max-width: 1024px) {
  .cover {
    height: -webkit-fill-available;
  }
}

@media (max-width: 768px) {
  border-radius: 0;
  min-height: 100vh;

  .cover {
    height: 100%;
  }
}

.info {
  position: relative;
  z-index: 2;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .info {
        padding-inline-start: 70px;
    }
}

@media (min-width: 1024px) { /* Changed max-width to 2000px */
    .info {
        padding-inline-start: 40px;
    }
}

h1, h2 {
  color: #FFFFFFFF;
  font-style: normal;
  line-height: normal;
  text-align: start;
}

h1 {
  font-size: 32px;
  font-weight: 800;
  margin: 9vh 0 0;

@media (max-width: 768px) {
  margin-top: 40px;
  font-size: 20px;
}
}

h2 {
  font-size: 32px;
  font-weight: 700;

@media (max-width: 768px) {
  font-size: 20px;
}
}

p {
  color: #FFFFFFFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: start;
  width: 55%;

  @media (max-width: 768px) {
    width: 100%;
  }
}


}

.cover {
  object-fit: cover;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1;
}

.guide {
  margin-top: 8vh;
  position: relative;
  background: #0A3041CC;
  backdrop-filter: blur(10px);
  padding: 18px 44px;
  display: flex;
  align-items: center;

@media (max-width: 768px) {
  margin-top: 50px;
  padding: 32px 44px 12px;
}

h2 {
  color: #FFFFFFFF;
  font-size: 20px;
  font-weight: 700;

@media (max-width: 768px) {
  font-size: 16px;
}
}

p {
  color: #FFFFFFFF;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.item {
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: transform 0.3s ease;
  margin-bottom: 10px;
  min-height: 120px; /* Adjust based on the maximum expected content */

  p.disc {
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 42px; /* Reserve space */
  }
@media (max-width: 768px) {
  margin-bottom: 20px;
}

img {
  margin-inline-end: 20px;
  max-width: 50px;
}

&:hover {
   transform: scale(1.1);
 }
}
}

/* Language-specific Styles */
.guide-ar {
  border-radius: 0 20px 0 0;
}

.guide-en {
  border-radius: 20px 0 0 0;
}

.hero-order {
  padding-top: 180px;
  background: #fff;

p {
  text-align: start;
  font-size: 18px;
  font-weight: 700;
  color: #AFAFAF;

@media (max-width: 768px) {
  font-size: 14px;
}
}

h1 {
  color: #0a3041;
  text-align: start;
  font-size: 40px;
  font-weight: 700;
  margin: 0;

@media (max-width: 768px) {
  font-size: 28px;
  margin-bottom: 40px;
}
}
}
.container-fluid.info {
  min-height: 100px; /* Prevent layout shifts */
  margin-top: 0px;
}
</style>
