<template>
  <div class="documents required-item">
    <div class="container">
      <h2 class="title-profile mt-0">{{ $route.name }}</h2>
      <div class="row">
        <div class="tabs">
          <div
            v-for="item in LegalPaperCategory"
            :key="item.id"
            @click="GetById(item)"
            class="item"
            :class="[Selected.id == item.id ? 'active' : '']"
          >
            <img :src="item.icon" alt="" />
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
        <div class="documents-tabel">
          <div class="row mb-3">
            <div class="col-md-4 mb-2">
              <div class="search w-100">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.7425 10.3427C12.7107 9.02147 13.1444 7.38334 12.9567 5.75606C12.769 4.12878 11.9739 2.63236 10.7303 1.5662C9.48666 0.500026 7.88635 -0.0572703 6.2495 0.00580047C4.61265 0.0688713 3.05997 0.747658 1.90209 1.90636C0.744221 3.06506 0.0665459 4.61823 0.00464636 6.25512C-0.0572531 7.89202 0.501188 9.49193 1.56825 10.7348C2.63531 11.9776 4.13229 12.7717 5.7597 12.9582C7.38711 13.1447 9.02494 12.7099 10.3455 11.7407H10.3445C10.3738 11.7807 10.4065 11.819 10.4425 11.8557L14.2924 15.7055C14.4799 15.8932 14.7342 15.9986 14.9995 15.9987C15.2647 15.9988 15.5192 15.8935 15.7068 15.706C15.8944 15.5185 15.9999 15.2642 16 14.9989C16.0001 14.7337 15.8948 14.4792 15.7073 14.2916L11.8575 10.4417C11.8217 10.4055 11.7833 10.3731 11.7425 10.3427ZM12.0004 6.49888C12.0004 7.22113 11.8582 7.9363 11.5818 8.60356C11.3054 9.27083 10.9003 9.87712 10.3896 10.3878C9.87889 10.8985 9.2726 11.3036 8.60533 11.58C7.93807 11.8564 7.2229 11.9987 6.50065 11.9987C5.77841 11.9987 5.06324 11.8564 4.39597 11.58C3.72871 11.3036 3.12242 10.8985 2.61171 10.3878C2.10101 9.87712 1.6959 9.27083 1.41951 8.60356C1.14312 7.9363 1.00086 7.22113 1.00086 6.49888C1.00086 5.04025 1.5803 3.64135 2.61171 2.60994C3.64312 1.57853 5.04202 0.999087 6.50065 0.999087C7.95929 0.999087 9.35818 1.57853 10.3896 2.60994C11.421 3.64135 12.0004 5.04025 12.0004 6.49888Z"
                    fill="#225476"
                  ></path>
                </svg>
                <input
                  @input="FilterSearch"
                  v-model="Search"
                  type="text"
                  :placeholder="$t('Search')"
                />
              </div>
            </div>
            <div class="col-md-3">
              <!-- <select name="" id="" class="bg-white">
                                <option value="">التصنيف حسب...</option>
                            </select> -->
            </div>
            <div class="col-md-3 mb-2"></div>
            <!-- <div class="col-md-2 mb-2" style="text-align: end;padding-inline-end: 0;">
                            <button class="btn-main" @click="toggleNewDocument">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.89474 12.8947C5.89474 13.1879 6.01118 13.469 6.21846 13.6763C6.42574 13.8836 6.70687 14 7 14C7.29313 14 7.57426 13.8836 7.78154 13.6763C7.98882 13.469 8.10526 13.1879 8.10526 12.8947V8.10526H12.8947C13.1879 8.10526 13.469 7.98882 13.6763 7.78154C13.8836 7.57426 14 7.29313 14 7C14 6.70687 13.8836 6.42574 13.6763 6.21846C13.469 6.01118 13.1879 5.89474 12.8947 5.89474H8.10526V1.10526C8.10526 0.812129 7.98882 0.531001 7.78154 0.323724C7.57426 0.116447 7.29313 0 7 0C6.70687 0 6.42574 0.116447 6.21846 0.323724C6.01118 0.531001 5.89474 0.812129 5.89474 1.10526V5.89474H1.10526C0.812129 5.89474 0.531001 6.01118 0.323724 6.21846C0.116447 6.42574 0 6.70687 0 7C0 7.29313 0.116447 7.57426 0.323724 7.78154C0.531001 7.98882 0.812129 8.10526 1.10526 8.10526H5.89474V12.8947Z"
                                        fill="white" />
                                </svg>
                                {{ $t('Add_a_document') }}
                            </button>
                        </div> -->
          </div>
          <div class="row head-tabel">
            <div class="col">
              {{ $t("Reference Number") }}
            </div>
            <div class="col">
              {{ $t("Section") }}
            </div>
            <div
              class="col"
              v-if="Selected != ''"
              v-for="j of Selected.attributes"
              :key="j.id"
            >
              {{ j.name }}
            </div>
            <div class="col">
              {{ $t("Document Status") }}
            </div>
            <div class="col">
              {{ $t("Expired Date") }}
            </div>
            <div class="col">
              {{ $t("Files") }}
            </div>
            <div class="col">
              {{ $t("Setting") }}
            </div>
          </div>
          <div
            class="row body-tabel"
            v-for="item in LegalPaper"
            :key="item.id"
            v-if="LegalPaper.length > 0"
          >
            <div class="col">
              {{ item.id }}
            </div>
            <div class="col">
              {{ item.category.name }}
            </div>
            <div
              class="col"
              v-if="Selected != ''"
              v-for="(j, index) of Selected.attributes"
              :key="index"
            >
              {{ item.attribute_values[index].attribute_value }}
            </div>
            <div class="col">
              {{ item.status }}
            </div>
            <div class="col">
              {{ item.ends_at }}
            </div>
            <div class="col">
              <a :href="item.file" download>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.14142 8.85858C6.06332 8.93668 5.93668 8.93668 5.85858 8.85858L2.38896 5.38896C2.31182 5.31182 2.31073 5.1871 2.3865 5.10862L3.15862 4.30893C3.2363 4.22848 3.36485 4.22735 3.44392 4.30642L4.90858 5.77108C5.03457 5.89707 5.25 5.80784 5.25 5.62966V0.2C5.25 0.0895427 5.33954 0 5.45 0H6.55C6.66046 0 6.75 0.0895431 6.75 0.2V5.62966C6.75 5.80784 6.96543 5.89707 7.09142 5.77108L8.55608 4.30642C8.63515 4.22735 8.7637 4.22848 8.84138 4.30893L9.6135 5.10862C9.68927 5.1871 9.68818 5.31182 9.61104 5.38896L6.14142 8.85858ZM1.5 12C1.0875 12 0.7345 11.8532 0.441 11.5597C0.1475 11.2662 0.0005 10.913 0 10.5V8.45C0 8.33954 0.0895431 8.25 0.2 8.25H1.3C1.41046 8.25 1.5 8.33954 1.5 8.45V10.3C1.5 10.4105 1.58954 10.5 1.7 10.5H10.3C10.4105 10.5 10.5 10.4105 10.5 10.3V8.45C10.5 8.33954 10.5895 8.25 10.7 8.25H11.8C11.9105 8.25 12 8.33954 12 8.45V10.5C12 10.9125 11.8532 11.2657 11.5597 11.5597C11.2662 11.8537 10.913 12.0005 10.5 12H1.5Z"
                    fill="#0a3041"
                  />
                </svg>
              </a>
            </div>
            <div class="col">
              <button
                v-if="item.status === 'about_to_end'"
                class="btn-main"
                style="font-size: 10px !important"
                @click="toggle(item)"
              >
                {{ $t("Renewal_Request") }}
              </button>
            </div>
          </div>
          <NoDataToShow v-else />
        </div>
      </div>
    </div>
    <Renewal :DocumentObj="DocumentObj" v-if="Show" @hide="toggle({})" />
    <NewDocument
      :Categories="LegalPaperCategory"
      v-if="ShowNewDocument"
      @hide="toggleNewDocument"
    />
    <Spinner />
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import axios from "axios";
import { notify } from "@kyvg/vue3-notification";
import Cookie from "cookie-universal";
const cookie = Cookie();
export default {
  data() {
    return {
      DocumentObj: {},
      Selected: "",
      pageLength: 5,
      Show: false,
      ShowNewDocument: false,
      LegalPaperCategory: [],
      LegalPaper: [],
      Search: "",
    };
  },
  components: {
    Renewal: defineAsyncComponent(() =>
      import("@/components/Profile/Documents/RenewalDocument.vue")
    ),
    NewDocument: defineAsyncComponent(() =>
      import("@/components/Profile/Documents/NewDocument.vue")
    ),
    NoDataToShow: defineAsyncComponent(() =>
      import("@/components/Global/NoDataToShow.vue")
    ),
    Spinner: defineAsyncComponent(() =>
      import("@/components/Global/Spinner.vue")
    ),
  },
  methods: {
    toggle(obj) {
      this.Show = !this.Show;
      this.DocumentObj = obj;
    },
    toggleNewDocument() {
      this.ShowNewDocument = !this.ShowNewDocument;
    },

    prevPage() {
      if (this.index != 1) {
        this.index--;
      }
    },
    nextPage() {
      if (this.index != this.pageLength) {
        this.index++;
      }
    },
    Get_Doc_categories() {
      axios
        .get(`/company/dashboard/legal-paper-categories`, {
          headers: {
            Authorization: `Bearer ${cookie.get("CompanyToken")}`,
          },
        })
        .then((res) => {
          this.LegalPaperCategory = res.data.data.LegalPaperCategory;
          this.Selected = this.LegalPaperCategory[0];
          this.GetById(this.Selected);
        })
        .catch(function (error) {
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
    GetById(item) {
      this.Selected = item;
      axios
        .get(`/company/dashboard/legal-papers?category_id=${item.id}`, {
          headers: {
            Authorization: `Bearer ${cookie.get("CompanyToken")}`,
          },
        })
        .then((res) => {
          this.LegalPaper = res.data.data.LegalPaper;
        })
        .catch(function (error) {
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
    FilterSearch() {
      var self = this;
      this.$store.dispatch("SETSpinner", true);
      axios
        .get(
          `/company/dashboard/legal-papers?category_id=${this.Selected.id}&search=${this.Search}`,
          {
            headers: {
              Authorization: `Bearer ${cookie.get("CompanyToken")}`,
            },
          }
        )
        .then((res) => {
          this.LegalPaper = res.data.data.LegalPaper;
          this.$store.dispatch("SETSpinner", false);
        })
        .catch(function (error) {
          self.$store.dispatch("SETSpinner", false);
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
  },
  mounted() {
    this.Get_Doc_categories();
  },
};
</script>
