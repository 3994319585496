<template>
   <div class="auth register">
    <img class="cover" src="../../assets/img/auth/Cover-register.png" alt="cover register">
    <div class="container">
        <Register />
    </div>
   </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'Login Page',
components: {
  Register: defineAsyncComponent( () => import('@/components/auth/register.vue') ),
},
}
</script>

