<template>
  <div class="required-item consulting">
    <div class="container">
      <h2 class="title-profile mt-0">{{ $route.name }}</h2>
      <div class="col-12">
        <div class="invoices p-0">
          <div class="head-section-profile">
            <div class="d-flex flex-column">
              <label class="mb-2">{{$t('Classification_according_to')}}</label>
              <select @change="filterByStatus">
                <option value="0">
                  {{ $t("All Orders") }}
                </option>
                <option v-for="item in Statusess" :key="item.id" :value="item.id">
                  {{ item.show_name }}
                </option>
              </select>
            </div>
            <div class="d-flex align-items-center">
              <div>
                <ul class="nav nav-pills nav-pills-order-details justify-content-end" id="pills-tab-invoices"
                  role="tablist">
                  <li class="nav-item" role="presentation" :class="[!mobile ? 'active' : '']">
                    <button data-index="row-view" class="nav-link" :class="[mobile ? 'active' : '']"
                      id="v-pills-row-view-tab" data-bs-toggle="pill" data-bs-target="#v-pills-row-view" type="button"
                      role="tab" aria-controls="v-pills-P-D-1" aria-selected="true">
                      <span class="fs-sm-4">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M0 0.999999C0 0.447714 0.447715 0 1 0H5.58824C6.14052 0 6.58824 0.447715 6.58824 1V5.58824C6.58824 6.14052 6.14052 6.58824 5.58824 6.58824H0.999999C0.447714 6.58824 0 6.14052 0 5.58824V0.999999Z"
                            fill="#f3f7f9" />
                          <path
                            d="M7.41176 0.999999C7.41176 0.447714 7.85948 0 8.41176 0H13C13.5523 0 14 0.447715 14 1V5.58824C14 6.14052 13.5523 6.58824 13 6.58824H8.41176C7.85948 6.58824 7.41176 6.14052 7.41176 5.58824V0.999999Z"
                            fill="#f3f7f9" />
                          <path
                            d="M7.41176 8.41176C7.41176 7.85948 7.85948 7.41176 8.41176 7.41176H13C13.5523 7.41176 14 7.85948 14 8.41176V13C14 13.5523 13.5523 14 13 14H8.41176C7.85948 14 7.41176 13.5523 7.41176 13V8.41176Z"
                            fill="#f3f7f9" />
                          <path
                            d="M0 8.41176C0 7.85948 0.447715 7.41176 1 7.41176H5.58824C6.14052 7.41176 6.58824 7.85948 6.58824 8.41176V13C6.58824 13.5523 6.14052 14 5.58824 14H0.999999C0.447714 14 0 13.5523 0 13V8.41176Z"
                            fill="#f3f7f9" />
                        </svg>
                      </span>
                    </button>
                  </li>
                  <li class="nav-item mx-2" role="presentation" :class="[mobile ? 'active' : '']">
                    <button :class="[!mobile ? 'active' : '']" data-index="card-view" class="nav-link"
                      id="v-pills-card-view-tab" data-bs-toggle="pill" data-bs-target="#v-pills-card-view" type="button"
                      role="tab" aria-controls="v-pills-card-view" aria-selected="true">
                      <span class="fs-sm-4">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M0 1.90909C0 0.854729 0.854729 0 1.90909 0H12.0909C13.1453 0 14 0.854729 14 1.90909V1.90909C14 2.96345 13.1453 3.81818 12.0909 3.81818H1.90909C0.854729 3.81818 0 2.96345 0 1.90909V1.90909Z"
                            fill="#0a3041" />
                          <path
                            d="M0 7C0 5.94564 0.854729 5.09091 1.90909 5.09091H12.0909C13.1453 5.09091 14 5.94564 14 7V7C14 8.05436 13.1453 8.90909 12.0909 8.90909H1.90909C0.854729 8.90909 0 8.05436 0 7V7Z"
                            fill="#0a3041" />
                          <path
                            d="M0 12.0909C0 11.0365 0.854729 10.1818 1.90909 10.1818H12.0909C13.1453 10.1818 14 11.0365 14 12.0909V12.0909C14 13.1453 13.1453 14 12.0909 14H1.90909C0.854729 14 0 13.1453 0 12.0909V12.0909Z"
                            fill="#0a3041" />
                        </svg>
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
              <div>
                <router-link to="/packages" class="btn-main">

                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.89474 12.8947C5.89474 13.1879 6.01118 13.469 6.21846 13.6763C6.42574 13.8836 6.70687 14 7 14C7.29313 14 7.57426 13.8836 7.78154 13.6763C7.98882 13.469 8.10526 13.1879 8.10526 12.8947V8.10526H12.8947C13.1879 8.10526 13.469 7.98882 13.6763 7.78154C13.8836 7.57426 14 7.29313 14 7C14 6.70687 13.8836 6.42574 13.6763 6.21846C13.469 6.01118 13.1879 5.89474 12.8947 5.89474H8.10526V1.10526C8.10526 0.812129 7.98882 0.531001 7.78154 0.323724C7.57426 0.116447 7.29313 0 7 0C6.70687 0 6.42574 0.116447 6.21846 0.323724C6.01118 0.531001 5.89474 0.812129 5.89474 1.10526V5.89474H1.10526C0.812129 5.89474 0.531001 6.01118 0.323724 6.21846C0.116447 6.42574 0 6.70687 0 7C0 7.29313 0.116447 7.57426 0.323724 7.78154C0.531001 7.98882 0.812129 8.10526 1.10526 8.10526H5.89474V12.8947Z"
                      fill="white" />
                  </svg>
                  {{ $t('Request_a_package') }}
                </router-link>
              </div>
            </div>
          </div>

          <div class="tab-content p-d mt-3" id="pills-tabContent">
            <div class="tab-pane fade show row-view" :class="[mobile ? 'active' : '']" id="v-pills-row-view"
              role="tabpanel" aria-labelledby="v-pills-row-view-tab">
              <div v-if="PackageOrders.length > 0" class="col-md-4" v-for="item in PackageOrders" :key="item.id">
                <div class="card-pack-req" style="box-shadow: 0px 5px 30px 0px rgba(10, 48, 65, 0.10); ">
                  <div class="d-flex justify-content-between align-items-center">
                    <h2 class="name">{{ item.package?.name }} </h2>
                    <li class="nav-item dropdown"
                      v-if="item.status?.id == 11 || item.status?.id == 12 || item.status?.id == 14">
                      <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <svg style="cursor: pointer;margin-top: -10px;" width="18" height="5" viewBox="0 0 18 5"
                          fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M14 2.5C14 3.60457 14.8954 4.5 16 4.5C17.1046 4.5 18 3.60457 18 2.5C18 1.39543 17.1046 0.5 16 0.5C14.8954 0.5 14 1.39543 14 2.5Z"
                            fill="#0a3041" />
                          <path
                            d="M7 2.5C7 3.60457 7.89543 4.5 9 4.5C10.1046 4.5 11 3.60457 11 2.5C11 1.39543 10.1046 0.5 9 0.5C7.89543 0.5 7 1.39543 7 2.5Z"
                            fill="#0a3041" />
                          <path
                            d="M8.66252e-07 2.5C8.17969e-07 3.60457 0.895431 4.5 2 4.5C3.10457 4.5 4 3.60457 4 2.5C4 1.39543 3.10457 0.5 2 0.5C0.895431 0.5 9.14534e-07 1.39543 8.66252e-07 2.5Z"
                            fill="#0a3041" />
                        </svg>
                      </a>
                      <ul class="dropdown-menu setting" :aria-labelledby="`navbarDropdown-${item.id}`">
                        <li v-if="item.status?.id == 11 || item.status?.id == 12 || item.status?.id == 14"
                          @click="PaymentRefund(item.id)">
                          <span> {{ $t('Payment Refund') }}</span>
                        </li>

                      </ul>
                    </li>
                  </div>

                  <div class="data-list">
                    <div class="item">
                      <div class="key">{{ $t('order_number') }}</div>
                      <div class="value">{{ item.id }}</div>
                    </div>
                    <div class="item">
                      <div class="key">{{ $t('Date_created') }}</div>
                      <div class="value">{{ formatDate(item.created_at) }} </div>
                    </div>
                    <div class="item">
                      <div class="key">{{ $t('Server_Provider') }}</div>
                      <div class="value">{{ item.service_provider?.name }}</div>
                    </div>
                  </div>
                  <div class="bottom-card">
                    <div class="status"
                      :style="{ backgroundColor: item.status.bg_color, color: item.status.text_color }">
                      {{ item.status.show_name }}
                    </div>
                    <router-link :to="`/packages/order/details/${item.id}`">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.31779 11.6027L0.373973 6.53275C0.299066 6.45593 0.245883 6.37271 0.214422 6.28309C0.182962 6.19347 0.167481 6.09745 0.16798 5.99502C0.16798 5.8926 0.183711 5.79658 0.215172 5.70696C0.246632 5.61733 0.299566 5.53412 0.373973 5.4573L5.31779 0.387326C5.45512 0.246494 5.6269 0.173005 5.83314 0.16686C6.03938 0.160714 6.21716 0.234203 6.36647 0.387326C6.51629 0.528159 6.59444 0.704328 6.60093 0.915833C6.60742 1.12734 6.53551 1.30965 6.3852 1.46277L2.71479 5.22684H11.0856C11.2978 5.22684 11.4758 5.30059 11.6197 5.44808C11.7635 5.59557 11.8351 5.77788 11.8346 5.99502C11.8346 6.21267 11.763 6.39524 11.6197 6.54273C11.4763 6.69022 11.2983 6.76371 11.0856 6.7632H2.71479L6.3852 10.5273C6.52253 10.6681 6.59444 10.8473 6.60093 11.065C6.60742 11.2826 6.53551 11.4619 6.3852 11.6027C6.24787 11.7564 6.07309 11.8332 5.86086 11.8332C5.64862 11.8332 5.4676 11.7564 5.31779 11.6027Z"
                          fill="#225476" />
                      </svg>
                    </router-link>
                  </div>
                </div>
              </div>

            </div>
            <div class="tab-pane fade show card-view" :class="[!mobile ? 'active' : '']" id="v-pills-card-view"
              role="tabpanel" aria-labelledby="v-pills-card-view-tab">
              <div class="consulting-tabel" v-if="PackageOrders.length > 0">
                <div class="row head-tabel">
                  <div class="col">
                    {{ $t("request_number") }}
                  </div>
                  <div class="col">
                    {{ $t("Package Name") }}
                  </div>
                  <div class="col">
                    {{ $t("Package Section") }}
                  </div>
                  <div class="col">
                    {{ $t("Date_created") }}
                  </div>
                  <div class="col">
                    {{ $t("Server_Provider") }}
                  </div>
                  <div class="col">
                    {{ $t("Status") }}
                  </div>
                  <div class="col">
                    {{ $t("Details") }}
                  </div>
                  <div class="col">
                    {{ $t("Management") }}
                  </div>
                </div>
                <div class="row body-tabel text-center" v-for="(item, index) in PackageOrders" :key="index">
                  <div class="col">
                    {{ item.id }}
                  </div>
                  <div class="col">
                    {{ item.package.name }}
                  </div>
                  <div class="col">
                    {{ item.package.package_category_name }}
                  </div>
                  <div class="col">
                    {{ item.created_at }}
                  </div>
                  <div class="col">
                    {{ item.service_provider?.name }}
                  </div>
                  <div class="col">
                    {{ item.status?.show_name }}
                  </div>
                  <div class="col">
                    <router-link :to="`/packages/order/details/${item.id}`" class="btn-main">
                      {{ $t("Details") }}
                    </router-link>
                  </div>
                  <div class="col position-relative">
                    <li class="nav-item dropdown" v-if="
                      item.status?.id != 19 &&
                      item.status?.id != 16 &&
                      item.status?.id != 1 &&
                      item.status?.id != 3 &&
                      item.status?.id != 4 &&
                      item.status?.id != 5 &&
                      item.status?.id != 6 &&
                      item.status?.id != 7 &&
                      item.status?.id != 22 &&
                      item.status?.id != 2 &&
                      item.status?.id != 23
                    ">
                      <a class="nav-link dropdown-toggle" href="#" :id="`Dropdown-${item.id}`" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <svg style="cursor: pointer; width: 40px" width="4" height="14" viewBox="0 0 4 14" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M2 3.40625C2.82843 3.40625 3.5 2.73468 3.5 1.90625C3.5 1.07782 2.82843 0.40625 2 0.40625C1.17157 0.40625 0.5 1.07782 0.5 1.90625C0.5 2.73468 1.17157 3.40625 2 3.40625Z"
                            fill="#225476" />
                          <path
                            d="M2 8.51562C2.82843 8.51562 3.5 7.84405 3.5 7.01562C3.5 6.1872 2.82843 5.51562 2 5.51562C1.17157 5.51562 0.5 6.1872 0.5 7.01562C0.5 7.84405 1.17157 8.51562 2 8.51562Z"
                            fill="#225476" />
                          <path
                            d="M2 13.5937C2.82843 13.5937 3.5 12.9222 3.5 12.0937C3.5 11.2653 2.82843 10.5938 2 10.5938C1.17157 10.5938 0.5 11.2653 0.5 12.0937C0.5 12.9222 1.17157 13.5937 2 13.5937Z"
                            fill="#225476" />
                        </svg>
                      </a>
                      <ul class="dropdown-menu setting" :aria-labelledby="`Dropdown-${item.id}`">
                        <!--                        <li-->
                        <!--                          v-if="-->
                        <!--                            item.status?.id == 2 ||-->
                        <!--                            item.status?.id == 9 ||-->
                        <!--                            item.status?.id == 10 ||-->
                        <!--                            item.status?.id == 18 ||-->
                        <!--                            item.status?.id == 20-->
                        <!--                          "-->
                        <!--                          @click="ShowRequest(item)"-->
                        <!--                        >-->
                        <!--                          <span> {{ $t("Re-Request_a_service") }}</span>-->
                        <!--                        </li>-->
                        <li v-if="
                          item.status?.id == 11 ||
                          item.status?.id == 12 ||
                          item.status?.id == 14
                        " @click="PaymentRefund(item.id)">
                          <span> {{ $t("Payment Refund") }}</span>
                        </li>
                        <!-- <li v-if="item.status?.id == 1 ||  item.status?.id == 3 || item.status?.id == 4 || item.status?.id == 5 || item.status?.id == 6 || item.status?.id == 7 || item.status?.id == 12"
                                                @click="CancelRequest(item.id)">
                                                        <span class="cancel"> {{ $t('Cancel Service') }}</span>
                                                </li> -->
                      </ul>
                    </li>
                  </div>
                </div>
              </div>
              <NoDataToShow v-else />

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import axios from 'axios';
import { notify } from "@kyvg/vue3-notification";
import Cookie from 'cookie-universal'
const cookie = Cookie()
export default {
  data() {
    return {
      PackageOrders: [],
      mobile: false,
      // index:1,
      // pageLength:5
    }
  },
  components: {
    NoDataToShow: defineAsyncComponent(() => import('@/components/Global/NoDataToShow.vue')),
  },
  methods: {
    CancelRequest(id) {
      axios
        .post(`/company/dashboard/package-requests/cancel-request/${id}`, null,
          {
            headers: { 'Authorization': `Bearer ${cookie.get('CompanyToken')}` }
          })
        .then((res) => {
          if (res.status == 200) {
            notify({
              type: "success",
              text: this.$i18n.locale == 'ar' ? 'تم ارسال الطلب بنجاح' : 'Send Employee Invitation Success',
            });
            this.GetPackageRequests();
          }

        })
        .catch(function (error) {
          notify({
            type: "error",
            text: error.response.data.message,
          });

        });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear(); // Full year
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits for day

      return `${year}/${month}/${day}`;
    },
    GetPackageRequests() {
      this.$store.commit('SET_Loader', true);
      axios.get(`/company/dashboard/package-requests`, {
        headers: {
          "Authorization": `Bearer ${cookie.get('CompanyToken')}`
        }
      })
        .then((res) => {
          this.PackageOrders = res.data.data.PackageRequest;
          console.log(" this.PackageOrders", this.PackageOrders);
          this.$store.commit('SET_Loader', false);
        })
        .catch(function (error) {
          this.$store.commit('SET_Loader', false);
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
    PaymentRefund(id) {
      axios
        .post(`/company/dashboard/package-requests/refund-request/${id}`,
          {
            headers: { 'Authorization': `Bearer ${cookie.get('CompanyToken')}` }
          })
        .then((res) => {
          if (res.status == 200) {
            notify({
              type: "success",
              text: this.$i18n.locale == 'ar' ? 'تم ارسال الطلب بنجاح' : 'Send Employee Invitation Success',
            });
            this.GetPackageRequests();
          }

        })
        .catch(function (error) {
          notify({
            type: "error",
            text: error.response.data.message,
          });

        });
    },
    GetStatuses() {
      axios.get(`/company/dashboard/statuses`, {
        headers: {
          "Authorization": `Bearer ${cookie.get('CompanyToken')}`
        }
      })
        .then((res) => {
          this.Statusess = res.data.data.Status;
        })
        .catch(function (error) {
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
    filterByStatus(event) {
      this.PackageOrders = []
      if (event.target.value == 0) {
        this.GetPackageRequests()
      }
      else {
        this.$store.dispatch('PackageOrdersByStatus', event.target.value).then(r => {
          this.$store.state.PackageOrders.forEach(element => {
            this.PackageOrders.push(element)
          })
        })
      }
    },

  },
  created() {
    if (window.innerWidth < 768) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  },
  mounted() {
    this.GetStatuses()
    this.GetPackageRequests();
  }
};
</script>
