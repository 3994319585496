<template>
  <div class="overlay open" @click="close"></div>
  <div class="modalCompany">
    <div class="close" @click="close">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.6581 1.33776L6.99582 6L11.6581 10.6622C11.79 10.7941 11.8641 10.973 11.8641 11.1595C11.8641 11.3461 11.79 11.525 11.6581 11.6569C11.5262 11.7887 11.3473 11.8628 11.1608 11.8628C10.9742 11.8628 10.7953 11.7887 10.6635 11.6569L6.00121 6.99461L1.33897 11.6569C1.20707 11.7887 1.02819 11.8628 0.841661 11.8628C0.655135 11.8628 0.476248 11.7887 0.344354 11.6569C0.21246 11.525 0.138364 11.3461 0.138364 11.1595C0.138364 10.973 0.212461 10.7941 0.344355 10.6622L5.0066 6L0.344355 1.33776C0.212461 1.20586 0.138364 1.02698 0.138364 0.840451C0.138364 0.653925 0.21246 0.47504 0.344354 0.343146C0.476248 0.211252 0.655134 0.137155 0.841661 0.137154C1.02819 0.137155 1.20707 0.211252 1.33897 0.343145L6.00121 5.00539L10.6635 0.343146C10.7953 0.211252 10.9742 0.137155 11.1608 0.137155C11.3473 0.137155 11.5262 0.211252 11.6581 0.343146C11.79 0.47504 11.8641 0.653925 11.8641 0.840452C11.8641 1.02698 11.79 1.20586 11.6581 1.33776Z"
          fill="#0a3041" />
      </svg>
    </div>
    <div class="title">
      <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.4969 3.2C7.07863 3.2 3.49688 6.78176 3.49688 11.2V36.8C3.49688 41.2182 7.07863 44.8 11.4969 44.8H37.0969C41.5151 44.8 45.0969 41.2182 45.0969 36.8V19.2C45.0969 18.3163 45.8132 17.6 46.6969 17.6C47.5806 17.6 48.2969 18.3163 48.2969 19.2V36.8C48.2969 42.9856 43.2825 48 37.0969 48H11.4969C5.31128 48 0.296875 42.9856 0.296875 36.8V11.2C0.296875 5.01441 5.31128 0 11.4969 0H29.0969C29.9806 0 30.6969 0.716344 30.6969 1.6C30.6969 2.48366 29.9806 3.2 29.0969 3.2H11.4969Z"
          fill="#225476" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M17.8969 16C17.8969 12.4654 20.7622 9.6 24.2969 9.6C27.8315 9.6 30.6969 12.4654 30.6969 16C30.6969 19.5346 27.8315 22.4 24.2969 22.4C20.7622 22.4 17.8969 19.5346 17.8969 16ZM24.2969 12.8C22.5296 12.8 21.0969 14.2327 21.0969 16C21.0969 17.7673 22.5296 19.2 24.2969 19.2C26.0642 19.2 27.4969 17.7673 27.4969 16C27.4969 14.2327 26.0642 12.8 24.2969 12.8Z"
          fill="#225476" />
        <path
          d="M11.4969 35.2C11.4969 29.0144 16.5113 24 22.6969 24H25.8969C32.0825 24 37.0969 29.0144 37.0969 35.2V36.8C37.0969 37.6837 36.3806 38.4 35.4969 38.4C34.6132 38.4 33.8969 37.6837 33.8969 36.8V35.2C33.8969 30.7818 30.3151 27.2 25.8969 27.2V32C25.8969 32.8837 25.1806 33.6 24.2969 33.6C23.4132 33.6 22.6969 32.8837 22.6969 32V27.2C18.2786 27.2 14.6969 30.7818 14.6969 35.2V36.8C14.6969 37.6837 13.9806 38.4 13.0969 38.4C12.2132 38.4 11.4969 37.6837 11.4969 36.8V35.2Z"
          fill="#225476" />
      </svg>
      <div>{{ $t('Invitation Employee') }} </div>
    </div>
    <Form class="form-contact row mt-3" @submit="InvitationEmployee" :validation-schema="schema">
      <div class="col-12 mb-3">
        <label class="label-feild" for="email">{{ $t('Email') }}</label>
        <Field class="contact-field" name="email" type="email" :placeholder="$t('Email')" />
        <ErrorMessage name="email" class="error-message" />
      </div>
      <div class="col-12 mb-3">
        <label class="label-feild">{{ $t('Select Employee Role') }}</label>
        <div class="d-flex felx-wrap">
          <div class="mb-3" v-for="item of Role" :key="item.id">
            <Field :value="item.id" name="Employee_Role" type="radio" :id="`role-${item.id}`" hidden />
            <label class="label-radio" :for="`role-${item.id}`">{{ $i18n.locale === 'ar' ? item.show_name.ar : item.show_name }}</label>
          </div>
        </div>
        <ErrorMessage name="Employee_Role" class="error-message" />
      </div>
      <div class="footer-card justify-content-center">
        <button class="btn-main secondary" type="submit">{{ $t('Send Invitation') }}</button>
      </div>
    </Form>
  </div>
</template>
<script>
import { notify } from "@kyvg/vue3-notification";
import axios from 'axios';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { configure } from "vee-validate";
import Cookie from 'cookie-universal';
const cookie = Cookie();
export default {
  data() {
    return {
      Role: [],
    }
  },
  props: {

  },
  setup() {
    configure({
      validateOnInput: true,
    });

    const schema = yup.object({
      email: yup.string().required(cookie.get('lang') == 'ar' ? 'حقل البريد الالكتروني مطلوب' : 'email is required').email(cookie.get('lang') == 'ar' ? 'يجب إدخال بريد إلكتروني صالح' : 'email must be valid'),
      Employee_Role: yup
        .string()
        .required(cookie.get('lang') === 'ar' ? 'يجب اختيار دور للموظف' : 'Employee role is required'),
    });
    return {
      schema,

    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    close() {
      this.$emit('hide')
    },
    InvitationEmployee(values) {
      const FData = new FormData();
      FData.append('email', values.email);
      FData.append('role_id', values.Employee_Role);
      axios
        .post(`/company/dashboard/send-invitation`, FData, {
          headers: {
            "Authorization": `Bearer ${cookie.get('CompanyToken')}`
          }
        })
        .then((res) => {
          if (res.status == 200) {
            notify({
              type: "success",
              text: this.$i18n.locale == 'ar' ? 'تم ارسال الطلب بنجاح' : 'Send Employee Invitation Success',
            });
           this.$emit('hide');
          }
        })
        .catch(function (error) {
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
    GetRoles(){
            axios.get(`/company/dashboard/roles`, {
                headers: {
                    "Authorization": `Bearer ${cookie.get('CompanyToken')}`
                }
            })
                .then((res) => {
                    this.Role = res.data.data.Role;
                    console.log(this.Invitations)
                })
                .catch(function (error) {
                    notify({
                        type: "error",
                        text: error.response.data.message,
                    });
                });
        },
  },
  mounted() {
    this.GetRoles();
  }

};
</script>
