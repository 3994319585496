<template>
    <div class="privacy">
        <HeadOrders :PathPageFrom="$t('Home')" :PathPageCenter="false" :PathPageTo="$t('PrivacyPolicy')" />
       <div class="container">
        <div class="banner">
            <div class="row align-items-center">
                <div class="col-md-6">
                  <h1 class="title">
                    {{ this.$i18n.locale =='ar' ?
                    `تفضل بالاطلاع على سياستنا!`
                    :
                    `Please view our policy!`
                    }}
                  </h1>
                  <p>{{$t('PrivacyPolicyDescription')}}</p>
                </div>
                <div class="col-md-6 d-flex justify-content-center">
                    <img src="@/assets/img/global/privacy.png" alt="PrivacyPolicy">
                </div>
            </div>
        </div>
        <div v-if="StaticPrivacy.length > 0" v-html="StaticPrivacy[0].value"></div>
       </div>
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
    name: 'Privacy Page',
    components: {
        HeadOrders: defineAsyncComponent(() => import('@/components/Global/HeadOrders.vue')),
    },
    data() {
    return {
      StaticPrivacy:[]
    };
  },
  methods:{
    handleStaticAbout(StaticContent){
    StaticContent.forEach((item) => {
    if (item.category == 'privacy_policy_page') {
      this.StaticPrivacy.push({
        id: item.id,
        category: item.category,
        key: item.key,
        value: item.value,
      });
    }
  });
    }
  },
  mounted(){
    this.$store.dispatch('GetStaticContent').then(r => {
          this.handleStaticAbout(this.$store.state.StaticContent);
    });
  }

}
</script>
