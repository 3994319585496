<template>
    <div class="services" >
        <div class="row">
            <div class="col-sm-3 guide" data-aos="fade-down">
                <h1>{{ this.$i18n.locale == 'ar' ? 'خدماتنا.. حيث تلتقي الجودة بالثقة' : 'Our services.. where quality meets trust'
                    }}
                </h1>
                <p>
                    {{$t('ServicesWeOffer')}}
                </p>
            </div>
            <div class="col-sm-9 services-list">
                <swiper class="swiper" :space-between="30" :speed="1000" :breakpoints="swiperOptions.breakpoints"
                    :slidesPerView="3" navigation :pagination="{ type: 'progressbar', }">
                    <swiper-slide v-for="item in Services" :key="item.id" @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave">
                        <div class="item">
                            <div class="image">
                                <img :src="item.image" loading="lazy" alt="service" style="width:90px; height:90px;">
                            </div>
                            <h2>{{ item.title }}</h2>
                            <p>{{ item.description }}</p>
                        </div>
                    </swiper-slide>

                </swiper>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            swiperOptions: {
                breakpoints: {
                    300: { slidesPerView: 1, spaceBetween: 50 },
                    576: { slidesPerView: 2, spaceBetween: 25 },
                    767: { slidesPerView: 2, spaceBetween: 25 },
                    991: { slidesPerView: 3, spaceBetween: 25 },
                    1200: { slidesPerView: 3, spaceBetween: 25 },
                }
            },


        }
    },
    computed: {
        Services() {
            return this.$store.state.Services_Type
        }
    },
    methods: {
        handleMouseEnter() {
            this.isHovered = true;
        },
        handleMouseLeave() {
            this.isHovered = false;
        }
    },
    mounted() {
        this.$store.dispatch('GetServicesType');
    }
};
</script>
