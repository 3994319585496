<template>
  <div class="rate-card open">
    <div class="overlay" @click="close"></div>
    <div class="card-package">
      <swiper class="swiper" @swiper="onSwiper" :space-between="50" :allowTouchMove="false" :navigation="{
      enabled: true,
      nextEl: '.myNext',
      prevEl: '.myPrev',
    }" :pagination="{ type: 'progressbar', }">
        <div class="index-steps">
          <div :class="[index >= 0 ? 'active' : '']" class="step">1</div>
          <div :class="[index > 0 ? 'active' : '']" class="step">2</div>
          <div :class="[index > 1 ? 'active' : '']" class="step">3</div>
        </div>
        <swiper-slide>
          <div class="col-12">
            <img height="110" src="@/assets/img/packages/rate.png">
          </div>
          <div class="col-12">
            <p class=" text-center mt-4 thanks-p">{{ this.$i18n.locale == `ar` ? `شكرا لاختياركم إتمام...` : `Thank
              you for
              choosing to etmaam...!` }}</p>
            <h2 class="title text-center" style="font-size: 30px; margin-bottom: 30px;">
              {{ this.$i18n.locale == `ar` ? `قم بتقييم الخدمة !` : `Rate the service!` }}</h2>
          </div>
          <p style="font-size: 12px;" class="opinion">
            {{ this.$i18n.locale == `ar` ? `آراؤكم هي مرآتنا التي نعكس فيها جودتنا. قم بتقييم تجربتك معنا وساعدنا في
            بناء
            مجتمع من العملاء الراضين.قيمنا الآن وكن جزءًا من عائلتنا!`
      : `Your opinions are our mirror in which we reflect our quality. Rate your experience with us and help us
            build
            a community of satisfied customers. Rate us now and become part of our family!` }}</p>

          <div class="que" v-if="indexx === 0" ref="question1">
            <p style="font-size:14px;">
              {{ this.$i18n.locale == `ar` ? `ما هو تقييمك لجودة الخدمة التي قدمناها لك؟` : `Rate the service!` }}</p>
            <div class="d-flex justify-content-center">
              <Rate name="quality" @my-event="handleArrayFromChild" />

            </div>
          </div>
          <div class="que" v-if="indexx === 1" ref="question2">
            <p style="font-size:14px;">
              {{ this.$i18n.locale == `ar` ? `كيف تقيم جودة التواصل مع فريق دعم العملاء؟` : `Rate the service!` }}</p>
            <div class="d-flex justify-content-center">
              <Rate name="qa_communitcation" @my-event="handleArrayFromChild" />
            </div>
          </div>
          <div class="que" v-if="indexx === 2" ref="question3">
            <p style="font-size:14px;">
              {{ this.$i18n.locale == `ar` ? `كيف تقيم سهولة استخدام منصتنا؟` : `Rate the service!` }}</p>
            <div class="d-flex justify-content-center">
              <Rate name="usability" @my-event="handleArrayFromChild" />
            </div>
          </div>
          <div class="que" v-if="indexx === 3" ref="question4">
            <p style="font-size:14px;">
              {{ this.$i18n.locale == `ar` ? `ما احتمالية قيامك بتوصية صديق او زميل بالتعامل معنا؟` : `Rate the
              service!` }}</p>
            <div class="d-flex justify-content-center">
              <Rate name="will_recommend" @my-event="handleArrayFromChild" />
            </div>
          </div>

        </swiper-slide>
        <swiper-slide class="temp">
         <div v-if="RateValue != 0">
          <div class="col-12">
            <img v-if="RateValue <= 4" height="150" src="@/assets/img/packages/low.png">
            <img v-if="RateValue <= 8 && RateValue > 4" height="150" src="@/assets/img/packages/good.png">
            <img v-if="RateValue > 8" height="150" src="@/assets/img/packages/excellent.png">
          </div>
          <h2 v-if="RateValue <= 4" class="title text-center" style="font-size: 30px; margin-bottom: 30px; margin-top: 50px;">
            {{ this.$i18n.locale == `ar` ? `تقييم ضعيف ! ` : `Low Rate !` }}</h2>
            <h2 v-if="RateValue <= 8 && RateValue > 4" class="title text-center" style="font-size: 30px; margin-bottom: 30px; margin-top: 50px;">
            {{ this.$i18n.locale == `ar` ? `تقييم جيد ! ` : `Good Rate !` }}
          </h2>
          <h2 v-if="RateValue > 8" class="title text-center" style="font-size: 30px; margin-bottom: 30px; margin-top: 50px;">
            {{ this.$i18n.locale == `ar` ? `تقييم عالي ! ` : `High Rate !` }}
          </h2>
          <div>
            <p v-if="RateValue <= 4" class=" text-center mt-4 thanks-p">{{ this.$i18n.locale == `ar` ?
             `اشرح لنا كيف يمكننا تحسين الخدمة للمرة القادمة !` :
      `Explain to us how we can improve the service for next time!` }}</p>
       <p v-else class=" text-center mt-4 thanks-p">{{ this.$i18n.locale == `ar` ? `
              اشرح لنا ما هي اهم الميزات التي
              اعجبتك !` :
      `Thank you for choosing to etmaam...!` }}</p>

            <!-- <textarea v-model="notes" type="text" name="massage" :placeholder="$t('Notes')"  cols="5" rows="3"></textarea> -->


            <textarea name="notes" v-model="notes" :placeholder="$t('Notes')" as="textarea" class=" p-4 "
              style="height: 115px;">
              </textarea>

          </div>
         </div>
        </swiper-slide>
        <swiper-slide class="temp">
          <div class="col-12">
            <img height="200" src="@/assets/img/packages/thanks.png">
          </div>
          <h2 class="title text-center" style="font-size: 30px; margin-bottom: 40px; margin-top: 40px;">
            {{ this.$i18n.locale == `ar` ? `نشكرك على التقييم !` : `Rate the service!` }}</h2>
          <p class=" text-center mt-4 thanks-p">{{ this.$i18n.locale == `ar` ? `نشكرك جزيل الشكر على وقتك الثمين في
            تقييم خدمتنا. ملاحظاتك القيمة تساعدنا على التحسن باستمرار.` :
      `Thank you for choosing to etmaam...!` }}</p>
        </swiper-slide>

      </swiper>

      <div class="navigation-button send-button">
  <div class="d-flex justify-content-between align-items-center">
    <div @click="HandelReview" class="btn-main me-auto" :class="[index != 0 ? 'd-none' : '']">
      <span>
        {{ this.$i18n.locale == `ar` ? `الخطوة التالية` : `Next Step` }}
      </span>
    </div>

  </div>
  <div class="d-flex justify-content-between align-items-center">
      <!-- <div @click="index = 0" class="next-step myPrev" :class="[index != 1 ? 'd-none' : '']">
        {{ this.$i18n.locale == 'ar' ? `الخطوة السابقة` : `Previous` }}
      </div> -->
      <div @click="HandelReview" class="btn-main mb-0 me-auto" :class="[index != 1 ? 'd-none' : '']">
        <span>
          {{ this.$i18n.locale == `ar` ? `ارسل ملاحظاتك` : `Submit evaluation` }}
        </span>
      </div>
    </div>
  <div @click="HandelReview" class="btn-main" :class="[index != 2 ? 'd-none' : '']">
    <span>
      {{ this.$i18n.locale == `ar` ? `ارسال التقييم` : `Submit evaluation` }}
    </span>
  </div>
</div>


    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import axios from 'axios';
import { notify } from "@kyvg/vue3-notification";
import Cookie from 'cookie-universal'
const cookie = Cookie()
export default {
  name: 'Blogs Details',
  data() {
    return {
      index: 0,
      indexx:0,
      swiper: null,
      service_req_id: null,
      ratings: {},
      // quality:0,
      // qa_communitcation:0,
      // usability:0,
      // will_recommend:0,
      notes: '',
      Status: false,
      RateValue:0
    }
  },

  methods: {
    close() {
      this.$store.state.showRate = false;
    },
    handleArrayFromChild(array) {
      this.ratings = { ...this.ratings, [array.name]: array.star }
 if (this.indexx < 4) {
      this.indexx++;
       this.scrollToNextQuestion();
    }
  if(this.indexx == 4){
    this.HandelReview()
  }
    },
 scrollToNextQuestion() {
    const nextQuestionRef = this.$refs[`question${this.indexx + 1}`];
    if (nextQuestionRef) {
      nextQuestionRef.scrollIntoView({
        behavior: 'smooth',  // التمرير بسلاسة
        block: 'start',  // جعل السؤال في أعلى الشاشة
      });
    }
  },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    HandelReview() {
      if (this.index == 0) {
        if (this.ratings.qa_communitcation != undefined &&
          this.ratings.quality != undefined &&
          this.ratings.usability != undefined &&
          this.ratings.will_recommend != undefined) {
          this.RateValue = this.ratings.qa_communitcation + this.ratings.quality + this.ratings.usability + this.ratings.will_recommend;
          this.index++;
          this.swiper.slideTo(this.index);
        }
        else {
          notify({
            type: "warning",
            text: this.$i18n.locale == 'ar' ? "التقييم مطلوب" : 'Rating Required',
          });
        }
      }
      if (this.index == 1) {
        if (this.notes != '') {
          this.index++;
          this.swiper.slideTo(this.index);

        }
        else {
          notify({
            type: "warning",
            text: this.$i18n.locale == 'ar' ? "حقل الملاحظات مطلوب" : 'Notes Required',
          });
        }
      }
      if (this.index == 2) {
        if(this.Status){
          const formData = {
          "service_request_id": this.$route.params.id,
          ...this.ratings,
          "notes": this.notes
        }
        axios.post(`/user/reviews`, formData, {
          headers: {
            "Authorization": `Bearer ${cookie.get('Token')}`
          }
        })
          .then((res) => {
            window.location.reload();
            notify({
              type: "success",
              title: "التقييم",
              text: this.$i18n.locale == 'ar' ? "تم ارسال التقييم" : 'Send Success',
            });
            // this.$store.commit("SET_Token", res.data.data);
          })
          .catch(function (error) {
            if (error) {
              notify({
                type: "error",
                title: "خـطـأ !!",
                text: error?.response?.data?.message,
              });
            }
          });
        }
          this.Status = true;
      }
    }
  },
//   methods: {
//   close() {
//     this.$store.state.showRate = false;
//   },
//   handleArrayFromChild(array) {
//     this.ratings = { ...this.ratings, [array.name]: array.star };

//     // تمرير المستخدم إلى القسم التالي تلقائيًا
//     const nextSection = document.querySelector(`.step-${this.index + 1}`);
//     if (nextSection) {
//       nextSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
//     }
//   },
//   onSwiper(swiper) {
//     this.swiper = swiper;
//   },
//   HandelReview() {
//     if (this.index === 0) {
//       if (
//         this.ratings.qa_communitcation !== undefined &&
//         this.ratings.quality !== undefined &&
//         this.ratings.usability !== undefined &&
//         this.ratings.will_recommend !== undefined
//       ) {
//         this.RateValue =
//           this.ratings.qa_communitcation +
//           this.ratings.quality +
//           this.ratings.usability +
//           this.ratings.will_recommend;
//         this.index++;
//         this.swiper.slideTo(this.index);
//       } else {
//         notify({
//           type: "warning",
//           text: this.$i18n.locale == "ar" ? "التقييم مطلوب" : "Rating Required",
//         });
//       }
//     } else if (this.index === 1) {
//       if (this.notes !== "") {
//         this.index++;
//         this.swiper.slideTo(this.index);

//         this.sendReview();
//       } else {
//         notify({
//           type: "warning",
//           text:
//             this.$i18n.locale == "ar" ? "حقل الملاحظات مطلوب" : "Notes Required",
//         });
//       }
//     }
//   },
//   sendReview() {
//     const formData = {
//       service_request_id: this.$route.params.id,
//       ...this.ratings,
//       notes: this.notes,
//     };

//     axios
//       .post(`/user/reviews`, formData, {
//         headers: {
//           Authorization: `Bearer ${cookie.get("Token")}`,
//         },
//       })
//       .then((res) => {
//         notify({
//           type: "success",
//           title: this.$i18n.locale == "ar" ? "التقييم" : "Review",
//           text:
//             this.$i18n.locale == "ar"
//               ? "تم ارسال التقييم بنجاح"
//               : "Review sent successfully",
//         });

//         // الانتقال إلى الخطوة الأخيرة
//         this.index = 2;
//         this.swiper.slideTo(this.index);
//       })
//       .catch((error) => {
//         notify({
//           type: "error",
//           title: this.$i18n.locale == "ar" ? "خطأ" : "Error",
//           text: error?.response?.data?.message || "Something went wrong!",
//         });
//       });
//   },
// },

  components: {
    Rate: defineAsyncComponent(() => import('@/components/Services/Order/Tabs/RateComponent.vue')),

  },
}
</script>
