<template>
  <div class="company-formation">
    <div class="container">
      <div class="banner-static-services">
        <h4>
        {{ $t('Embark on your business success journey in Saudi Arabia') }}
        </h4>
        <p>
          {{ $t('Etmam Services Company is considered one of the leading companies in providing business services in Saudi Arabia, as it provides a diverse package of services specifically designed to meet the needs of Saudi, Gulf and foreign investors aspiring to establish and grow their businesses in the Kingdom. With Etmam Company, you can benefit from comprehensive and innovative solutions starting from initial consultations, through the establishment process, all the way to strategic expansion, including organizing financial matters, opening bank accounts, and providing support and strategic consultations.') }}
        </p>
      </div>
      <Contact />
      <div class="row steps-open-company align-items-center">
        <div class="col-md-4 mb-3">
          <div class="title">
            {{ $t('Steps to open a company') }}
          </div>
          <div class="title-2">
            {{ $t('In the Kingdom of Saudi Arabia') }}
          </div>
          <p>
            {{ $t('open company desc') }}
          </p>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-6 mb-2">
              <div class="item">
                <div class="card-number">1</div>
                <div class="title">{{ $t('Pre-incorporation') }}</div>
                <ul>
                  <li>{{ $t('Get a free consultation from Etmam') }}</li>
                  <li>{{ $t('Determine the legal structure of the company') }}</li>
                  <li>{{ $t('Choose company activities') }}</li>
                  <li>{{ $t('Reserve a trade name for your company') }}</li>
                </ul>
              </div>
            </div>
            <div class="col-md-6 mb-2">
              <div class="item">
                <div class="card-number">2</div>
                <div class="title">{{ $t('Documents required to establish national and Gulf companies') }}</div>
                <ul>
                 <li>{{ $t('Identities of owners, partners and managers') }}</li>
                 <li>{{ $t('Trade name reservation') }}</li>
                 <li>{{ $t('Determine the entity type (enterprise/company)') }}</li>
                 <li>{{ $t('Legitimate authorization or agency') }}</li>
                </ul>
              </div>
            </div>
            <div class="col-md-6 mb-2">
              <div class="item">
                <div class="card-number">3</div>
                <div class="title">{{ $t('Documents required to establish foreign companies') }}</div>
                <ul>
                 <li>{{ $t('Identities of owners, partners and managers') }}</li>
                 <li>{{ $t('Investment license application form') }}</li>
                 <li>{{ $t('Certified budget or financial statements') }}</li>
                 <li>{{ $t('Commercial Registration (certified by the Saudi Embassy)') }}</li>
                 <li>{{ $t('Legitimate authorization or agency') }}</li>
                </ul>
              </div>
            </div>
            <div class="col-md-6 mb-2">
              <div class="item">
                <div class="card-number">4</div>
                <div class="title">{{ $t('Documents required for the incorporation process') }}</div>
                <ul>
                  <li>{{ $t('Obtaining an investment license (for foreign companies)') }}</li>
                  <li>{{ $t('Obtain a commercial register for your activity') }}</li>
                  <li>{{ $t('National address registration') }}</li>
                  <li>{{ $t('Registration for zakat and taxes') }}</li>
                  <li>{{ $t('Opening a bank account for the company') }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Slider
      :Title="$t('BusinessSetup')"
      :Title_Seconde="$t('In the Kingdom of Saudi Arabia')"
      :Imag="`/slider-formation.png`" />
    </div>
    <div class="what-we-offer">
      <div class="container">
        <div class="title">{{ $t('What we offer you !') }}</div>
        <p class="w8">
          {{ this.$i18n.locale == 'ar' ?
          `تقدم إتمام للخدمات مجموعة كبيرة من الخدمات تصل إلى أكثر من 500 خدمة التي تحتاجها المنشآت داخل المملكة العربية السعودية؛ سواء عبر فريقها أو عبر الشراكة مع شركاء إتمام، حيث تمتلك فريق عملٍ وشركاء مؤهلين لإنجاز الخدمات بدقة عالية وفي وقت يسير.`
          :
          `Etmaam Services provides a wide range of services amounting to more than 500 services that establishments need within the Kingdom of Saudi Arabia. Whether through its team or through partnership with Etmam partners, it has a team and qualified partners to complete the services with high accuracy and in a short time.`
          }}
        </p>
        <div class="row mt-5">
          <div class="col-md-3 mb-3 item">
            <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="19.6875" y="37.2444" width="23.9905" height="4.49822" rx="2" stroke="white" stroke-width="2" />
              <rect x="21.625" y="33.496" width="19.4923" height="3.74852" rx="1.87426" stroke="white"
                stroke-width="2" />
              <rect x="13.7891" y="14.7831" width="19.4923" height="4.49822" rx="2"
                transform="rotate(-45 13.7891 14.7831)" stroke="white" stroke-width="2" />
              <path
                d="M7.25015 35.8118C6.4691 35.0307 6.4691 33.7644 7.25015 32.9834L21.2094 19.0241L25.9805 23.7952L12.0212 37.7545C11.2402 38.5355 9.97386 38.5355 9.19281 37.7545L7.25015 35.8118Z"
                stroke="white" stroke-width="2" />
              <rect x="27.0391" y="28.0363" width="19.4923" height="4.49822" rx="2"
                transform="rotate(-45 27.0391 28.0363)" stroke="white" stroke-width="2" />
              <rect x="18.5547" y="16.3735" width="14.9941" height="14.2444" transform="rotate(-45 18.5547 16.3735)"
                stroke="white" stroke-width="2" />
            </svg>
            <div class="title">
              {{ this.$i18n.locale == 'ar' ? `الالتزام القانوني و الامتثال`:`Legal compliance and compliance`}}
            </div>
            <p>

              {{ this.$i18n.locale == 'ar' ? `سنضمن اتباع شركتك لجميع القوانين واللوائح ذات الصلة، بما في ذلك قوانين العمل، ولوائح الصحة والسلامة، واللوائح البيئية`:`We will ensure that your company follows all relevant laws and regulations, including employment laws, health and safety regulations, and environmental regulations`}}
            </p>
          </div>
          <div class="col-md-3 mb-3 item">
            <svg width="36" height="34" viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M25.7964 5.28866V3C25.7964 1.89543 24.9009 1 23.7964 1H8.03377C7.50545 1 6.99859 1.20904 6.62388 1.58147L1.82449 6.35163C1.44677 6.72704 1.23438 7.2376 1.23438 7.77015V31C1.23438 32.1046 2.1298 33 3.23437 33H23.7964C24.9009 33 25.7964 32.1046 25.7964 31V19.1443M8.20467 3.30928C8.20467 3.99599 8.20467 4.84057 8.20467 5.59811C8.20467 6.70268 7.30924 7.59794 6.20467 7.59794H3.88973M11.1919 26.732L12.5196 25.4124M12.5196 25.4124V23.6052C12.5196 23.0727 12.732 22.5621 13.1097 22.1867L18.7102 16.6204M12.5196 25.4124H15.014C15.5423 25.4124 16.0491 25.2033 16.4238 24.8309L21.6974 19.5894M32.5295 8.82339L33.8362 7.52466C34.2139 7.14925 34.4263 6.63869 34.4263 6.10614V5.96907C34.4263 4.8645 33.5308 3.96907 32.4263 3.96907H32.2638C31.7355 3.96907 31.2287 4.17811 30.854 4.55054L29.5422 5.85432M32.5295 8.82339L33.331 9.62001C34.1178 10.402 34.1178 11.675 33.331 12.4571L28.2623 17.4948M32.5295 8.82339L21.6974 19.5894M32.5295 8.82339L29.5422 5.85432M18.7102 16.6204L21.6974 19.5894M18.7102 16.6204L29.5422 5.85432"
                stroke="white" stroke-width="2" />
            </svg>
            <div class="title">
              {{ $t('BusinessSetup')}}
            </div>
            <p>

              {{ this.$i18n.locale == 'ar' ? `
                نحن نمتلك أفضل فريق قانوني في المملكة العربية السعودية مع سنوات من الخبرة والمعرفة، ونقدمها لك لتستفيد
              وتتمكن من رؤية السوق بمنظور مختلف.`:`We will help you register your company with the relevant government agencies and obtain the necessary licenses and permits.`}}
            </p>
          </div>
          <div class="col-md-3 mb-3 item">
            <svg width="46" height="38" viewBox="0 0 46 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M31.9684 10.3201L31.8358 10.3201C31.2835 10.3201 30.8358 10.7678 30.8358 11.3201V11.4527C30.8358 11.7179 30.9411 11.9723 31.1287 12.1598L31.8724 12.9035C32.0356 13.0667 32.1373 13.2814 32.1603 13.5111L32.3488 15.396C32.4395 16.3035 33.6418 16.5594 34.0943 15.7676L34.1151 15.7311C34.4111 15.2131 35.1185 15.1184 35.5403 15.5402L35.5956 15.5955C35.6858 15.6858 35.7473 15.8006 35.7723 15.9258L35.8233 16.1808C35.9394 16.7611 36.7497 16.8116 36.9368 16.2501C36.9914 16.0865 37.1165 15.9557 37.2774 15.8938C37.8819 15.6613 37.7182 14.7632 37.0706 14.7632L36.8657 14.7632C36.7006 14.7632 36.5424 14.6976 36.4257 14.5809L36.3573 14.5126C35.9774 14.1327 36.0403 13.4989 36.485 13.1974C37.1914 12.7184 36.8601 11.6091 36.0066 11.6091L34.0858 11.6091C33.8206 11.6091 33.5662 11.5037 33.3787 11.3162L32.6755 10.613C32.4879 10.4255 32.2336 10.3201 31.9684 10.3201Z"
                stroke="white" stroke-width="2" />
              <path
                d="M8.78125 27.0208H20.8125M28.4687 8.79167L32.8658 4.39461C33.2563 4.00408 33.8895 4.00408 34.28 4.39461L43.8033 13.9179C44.1938 14.3084 44.1938 14.9416 43.8033 15.3321L28.4687 30.6667"
                stroke="white" stroke-width="2" />
              <path d="M28.4688 22.2813L31.75 19M28.8333 26.2917L33.9375 21.1875" stroke="white" stroke-width="2" />
              <path
                d="M1.49219 6.60417V35.5C1.49219 36.0523 1.9399 36.5 2.49219 36.5H25.6484C26.2007 36.5 26.6484 36.0523 26.6484 35.5V5.41667C26.6484 4.86438 26.2007 4.41667 25.6484 4.41667H2.49219C1.9399 4.41667 1.49219 3.96895 1.49219 3.41667V2.5C1.49219 1.94772 1.9399 1.5 2.49219 1.5H26.6484"
                stroke="white" stroke-width="2" />
              <ellipse cx="14.6198" cy="15.7188" rx="7.29166" ry="7.29167" stroke="white" stroke-width="2" />
              <ellipse cx="14.6198" cy="15.7188" rx="2.91667" ry="7.29167" stroke="white" stroke-width="2" />
              <ellipse cx="14.6146" cy="15.7188" rx="2.91667" ry="7.29166" transform="rotate(90 14.6146 15.7188)"
                stroke="white" stroke-width="2" />
            </svg>
            <div class="title">
              {{ this.$i18n.locale == 'ar' ? `تصاريح العمل والتأشيرات`:`Work permits and visas`}}
            </div>
            <p>

              {{ this.$i18n.locale == 'ar' ? `
                                                   سنساعدك في الحصول على التأشيرات وتصاريح العمل اللازمة لموظفيك. نقدم أيضاً خدمات إدارة الموظفين للتعامل مع توظيف طاقم عملك ومساعدتك في تلبية متطلبات السعودية
              `:`  We will help you obtain the necessary visas and work permits for your employees. We also offer employee management services to handle your staff recruitment and help you meet Saudi requirements`}}
            </p>
          </div>
          <div class="col-md-3 mb-3 item">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.225 28.281H14.7348M16.4898 28.281H19.2977M21.0526 28.281H24.2115M25.6155 28.281H29.1253M13.6819 30.7476H16.8408M18.5957 30.7476H21.7546M23.5095 30.7476H26.6684M15.0858 33.919H24.9135M9.11903 16.6524V3.5C9.11903 2.39543 10.0145 1.5 11.119 1.5H29.5823C30.6868 1.5 31.5823 2.39543 31.5823 3.5V16.6524M14.0329 7.1381H26.6684M14.0329 10.3095H26.6684M7.36409 18.7667H32.9862M33.6882 15.9476H34.1451C35.2497 15.9476 36.1451 16.843 36.1451 17.9476V19.2333C36.1451 20.3379 35.2497 21.2333 34.1451 21.2333H33.6882M7.0131 15.9476H6.20519C5.10062 15.9476 4.20519 16.843 4.20519 17.9476V19.2333C4.20519 20.3379 5.10062 21.2333 6.20519 21.2333H7.0131M25.1577 21.8442L20.954 24.2182C20.3595 24.5539 19.6348 24.5632 19.0319 24.2427L14.473 21.8197C14.184 21.6661 13.8617 21.5857 13.5344 21.5857H9.37617C8.58052 21.5857 7.86054 22.0573 7.54269 22.7867L1.91502 35.701C1.33934 37.0221 2.30745 38.5 3.74851 38.5H36.2508C37.6919 38.5 38.66 37.0221 38.0843 35.701L32.4566 22.7867C32.1388 22.0573 31.4188 21.5857 30.6231 21.5857H26.1412C25.7966 21.5857 25.4578 21.6748 25.1577 21.8442Z"
                stroke="white" stroke-width="2" />
            </svg>
            <div class="title">

              {{ this.$i18n.locale == 'ar' ? `الزكاة و الضرائب و المعاملات المحاسبية`:`Zakat, taxes and accounting transactions`}}
            </div>
            <p>

              {{ this.$i18n.locale == 'ar' ? `  نحن نمتلك أفضل فريق قانوني في المملكة العربية السعودية مع سنوات من الخبرة والمعرفة، ونقدمها لك لتستفيد
              وتتمكن من رؤية السوق بمنظور مختلف.`:` We have the best legal team in the Kingdom of Saudi Arabia with years of experience and knowledge, and we offer it to you so that you can benefit and be able to see the market from a different perspective.`}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="steps-termination">
        <div class="row">
          <div class="col-md-4 mb-3 info">
            <h3>{{ $t('Termination steps') }}</h3>
            <h2>{{ $t('Service') }}</h2>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6 mb-3 d-flex align-items-center">
                <div class="item d-flex align-items-center">
                  <img src="@/assets/img/services-page/termination-1.svg" alt="">
                  <div>
                    <h4>{{ $t('Planning') }}</h4>
                    <p>{{ this.$i18n.locale == 'ar' ? `تحديد الخطوات اللازمة لتحقيق و الوصول للهدف المرجو.` :
                    `Determine the steps necessary to achieve and reach the desired goal.` }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3 d-flex align-items-center">
                <div class="item d-flex align-items-center">
                  <img src="@/assets/img/services-page/termination-2.svg" alt="">
                  <div>
                    <h4>{{ $t('Open a bank account') }}</h4>
                    <p>{{ this.$i18n.locale == 'ar' ? `للقيام بلعمليات المالية لإتمام اعمالك بشكل متكامل و سهل.`:`To carry out financial operations to complete your work in an integrated and easy manner.` }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3 d-flex align-items-center">
                <div class="item d-flex align-items-center">
                  <img src="@/assets/img/services-page/termination-3.svg" alt="">
                  <div>
                    <h4>{{ $t('Establishment') }}</h4>
                    <p>{{ this.$i18n.locale == 'ar' ? ` تحديد الفكرة من خلال تحليل السوق ووضع خطة عمل.`:` Determine the idea through market analysis and develop a business plan.` }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3 d-flex align-items-center">
                <div class="item d-flex align-items-center">
                  <img src="@/assets/img/services-page/termination-4.svg" alt="">
                  <div>
                    <h4>{{ $t('Follow up and improvement') }}</h4>
                    <p>{{ this.$i18n.locale == 'ar' ? ` تحديد الفكرة من خلال تحليل السوق ووضع خطة عمل.`:` Determine the idea through market analysis and develop a business plan.` }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
export default {
  components: {
    Contact: defineAsyncComponent(() => import('./component/contact-services.vue')),
    Slider: defineAsyncComponent(() => import('./component/slider-static-services.vue')),
  },



}
</script>
