<template>
    <div id="packages">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <HeadSections class="packages-img"
    :TitlePage="$i18n.locale == 'ar' ? 'الخطة المناسبة !':'The right plan!'"
    :TitleSec="$i18n.locale == 'ar' ? 'لتسهيل مشروعك و إتمام العمالك على أتم وجه':'To facilitate your project and complete your work to perfection'"
    :PathPageFrom="$t('Home')"
    :PathPageTo="$t('Packages')" />
    <div class="container" style="overflow: hidden; margin-top: -180px;">
    <IndexPackages />
    </div>
  </div>
  </template>
  <script>
  import { defineAsyncComponent } from 'vue'
  export default {
  name: 'Packages Page',
  components: {
    HeadSections: defineAsyncComponent( () => import('@/components/Global/HeadSections.vue') ),
    IndexPackages: defineAsyncComponent( () => import('@/components/Packages/IndexPackages.vue') ),
  },
  }
  </script>



