<template>
  <div class="register-card">
    <div class="row ">
      <div class="col-md-6 d-md-block d-none side">
        <img class="side-img" src="../../assets/img/auth/register-backgroun.png" alt="background">
        <div class="etmaam" :class="[this.$i18n.locale == 'en' ? 'en' : '']">
          <img src="../../assets/img/global/Logo.webp" alt="Etmaam Logo">
          <p>
            {{
          this.$i18n.locale == 'ar' ?
            `نحن هنا لنحقق رؤيتك ونساعدك على تحقيق أهدافك بكفاءة واحترافية.`
            :
            `We are here to realize your vision and help you achieve your goals efficiently and professionally.`
        }}
          </p>
        </div>
      </div>
      <div class="col-md-6 position-relative" style="z-index: 6;">
        <Form class="auth-form" @submit="HandelSubmit" :validation-schema="currentSchema">
          <div class="row">
            <div class="col-12">
              <h1 class="title">{{ $t('Register') }}</h1>
            </div>
            <div class="col-12 mb-4">
              <h3>
                {{
          this.$i18n.locale == 'ar' ?
            `قبل أن تبدأ ! هل لديك سجل تجاري؟`
            :
            `Before you start! Do you have a commercial register?`
        }}
              </h3>
              <div class="d-flex mt-2">
                <div class="d-flex align-items-center">
                  <input v-model="comercial" value="1" checked type="radio" name="comercial_radio">
                  <label for="">{{ this.$i18n.locale == 'ar' ? 'نعم لدي' : 'Yes I have' }}</label>
                </div>
                <div class="d-flex align-items-center">
                  <input v-model="comercial" value="0" type="radio" name="comercial_radio">
                  <label for="">{{ this.$i18n.locale == 'ar' ? 'ليس لدي' : `I don't have` }}</label>
                </div>
              </div>
            </div>

            <div class="col-12 field">
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 7.29297C9.79493 7.29297 11.25 5.83789 11.25 4.04297C11.25 2.24804 9.79493 0.792969 8 0.792969C6.20507 0.792969 4.75 2.24804 4.75 4.04297C4.75 5.83789 6.20507 7.29297 8 7.29297Z"
                  stroke="#0a3041" stroke-width="1.2" />
                <path
                  d="M14.5 13.5492C14.5 15.5683 14.5 17.2055 8 17.2055C1.5 17.2055 1.5 15.5683 1.5 13.5492C1.5 11.5302 4.41037 9.89297 8 9.89297C11.5896 9.89297 14.5 11.5302 14.5 13.5492Z"
                  stroke="#0a3041" stroke-width="1.2" />
              </svg>
              <Field name="name" type="text" :placeholder="$t('FullName')" />
              <ErrorMessage name="name" class="error-message" />
            </div>
            <div class="col-12 field" v-if="comercial == 1">
              <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M1.11415 15.9767C0.958032 15.976 0.807907 15.9166 0.693569 15.8103C0.578175 15.7033 0.509024 15.5555 0.500804 15.3984C0.492949 15.2429 0.542883 15.0899 0.640997 14.969C0.742539 14.8473 0.886701 14.769 1.04405 14.7499H2.25322V3.41077C2.25057 3.20784 2.30719 3.00854 2.41614 2.83732C2.52509 2.6661 2.68162 2.5304 2.86656 2.44685H2.96294L9.97259 0.115923C10.0803 0.0648103 10.1958 0.0322229 10.3143 0.0195312H10.4457C10.6057 0.0391287 10.7588 0.0961701 10.8926 0.186026C11.0238 0.271903 11.1345 0.385586 11.2168 0.519016C11.2949 0.656963 11.3454 0.808729 11.3658 0.965923V6.09221L14.8706 6.80201C15.0903 6.84606 15.29 6.95975 15.4401 7.12623C15.5895 7.29581 15.6842 7.50656 15.7117 7.73087V14.7412H16.8859C17.042 14.7419 17.1921 14.8014 17.3064 14.9077C17.4218 15.0146 17.491 15.1624 17.4992 15.3195C17.507 15.4751 17.4571 15.628 17.359 15.7489C17.2571 15.8688 17.1125 15.9442 16.9559 15.9592H1.11415V15.9767ZM11.3658 14.7499H14.5201V7.97623L11.3658 7.3453V14.7499ZM3.4799 3.53345V14.7499H10.1391V1.31644L3.4799 3.53345Z"
                  fill="#0a3041" />
              </svg>
              <Field name="company" type="text" :placeholder="$t('CompanyName')" />
              <ErrorMessage name="company" class="error-message" />
            </div>
            <div class="col-12 field position-relative" style="margin-bottom: 8px;">
              <svg style="position: absolute;z-index: 3;" width="14" height="18" viewBox="0 0 14 18" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.85077 17.8343C2.25742 17.8441 1.68096 17.6366 1.23003 17.2508C1.03033 17.0756 0.870029 16.8601 0.759682 16.6185C0.649334 16.3768 0.59145 16.1145 0.589844 15.8489V2.1537C0.59347 1.88682 0.653881 1.62377 0.767058 1.38204C0.880235 1.14032 1.04358 0.925463 1.24624 0.751767C1.69717 0.365988 2.27363 0.158463 2.86697 0.168303H11.1327C11.7261 0.158463 12.3025 0.365988 12.7534 0.751767C12.9561 0.925463 13.1195 1.14032 13.2326 1.38204C13.3458 1.62377 13.4062 1.88682 13.4098 2.1537V15.8489C13.4042 16.1154 13.3429 16.3778 13.2298 16.6192C13.1168 16.8607 12.9545 17.0758 12.7534 17.2508C12.3025 17.6366 11.7261 17.8441 11.1327 17.8343H2.85077ZM2.85077 1.27851C2.60485 1.26522 2.36367 1.34982 2.17994 1.51381C1.9962 1.67781 1.88486 1.90786 1.87022 2.1537V15.8489C1.88486 16.0947 1.9962 16.3248 2.17994 16.4888C2.36367 16.6528 2.60485 16.7374 2.85077 16.7241H11.1327C11.3786 16.7374 11.6198 16.6528 11.8035 16.4888C11.9873 16.3248 12.0986 16.0947 12.1133 15.8489V2.1537C12.0986 1.90786 11.9873 1.67781 11.8035 1.51381C11.6198 1.34982 11.3786 1.26522 11.1327 1.27851H2.85077ZM5.54929 15.2897C5.38253 15.2891 5.22152 15.2288 5.09548 15.1196C5.03184 15.0729 4.98008 15.0119 4.94441 14.9415C4.90873 14.8711 4.89014 14.7933 4.89014 14.7144C4.89014 14.6355 4.90873 14.5577 4.94441 14.4873C4.98008 14.4169 5.03184 14.3559 5.09548 14.3092C5.2223 14.2023 5.38344 14.1448 5.54929 14.1471H8.43419C8.60004 14.1448 8.76118 14.2023 8.888 14.3092C8.95164 14.3559 9.0034 14.4169 9.03907 14.4873C9.07475 14.5577 9.09334 14.6355 9.09334 14.7144C9.09334 14.7933 9.07475 14.8711 9.03907 14.9415C9.0034 15.0119 8.95164 15.0729 8.888 15.1196C8.76196 15.2288 8.60095 15.2891 8.43419 15.2897H5.54929Z"
                  fill="#0a3041" />
              </svg>
              <input id="phone" type="tel" name="phone" :maxlength="Max" pattern="\d{9}">
              <p id="output" class="error-message mt-1 mb-1"></p>
            </div>
            <div class="col-12 field">
              <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.77042 3.87129C1.48293 3.70649 1.11628 3.80596 0.951487 4.09345C0.786694 4.38094 0.88616 4.74759 1.17365 4.91238L1.77042 3.87129ZM7.36455 7.7695L7.666 7.25071L7.66294 7.24896L7.36455 7.7695ZM10.6155 7.7695L10.3177 7.24859L10.314 7.25072L10.6155 7.7695ZM16.8221 4.91273C17.1098 4.74829 17.2097 4.38176 17.0452 4.09407C16.8808 3.80639 16.5143 3.70648 16.2266 3.87093L16.8221 4.91273ZM4.70495 1.64688H13.2922V0.446875H4.70495V1.64688ZM13.2922 1.64688C14.7676 1.64688 15.9636 2.84288 15.9636 4.31823H17.1636C17.1636 2.18014 15.4303 0.446875 13.2922 0.446875V1.64688ZM15.9636 4.31823V11.6788H17.1636V4.31823H15.9636ZM15.9636 11.6788C15.9636 13.1541 14.7676 14.3501 13.2922 14.3501V15.5501C15.4303 15.5501 17.1636 13.8169 17.1636 11.6788H15.9636ZM13.2922 14.3501H4.70495V15.5501H13.2922V14.3501ZM4.70495 14.3501C3.2296 14.3501 2.03359 13.1541 2.03359 11.6788H0.833594C0.833594 13.8169 2.56686 15.5501 4.70495 15.5501V14.3501ZM2.03359 11.6788V4.31823H0.833594V11.6788H2.03359ZM2.03359 4.31823C2.03359 2.84288 3.2296 1.64688 4.70495 1.64688V0.446875C2.56686 0.446875 0.833594 2.18014 0.833594 4.31823H2.03359ZM1.17365 4.91238L7.06617 8.29005L7.66294 7.24896L1.77042 3.87129L1.17365 4.91238ZM7.06311 8.28828C7.64835 8.62834 8.31315 8.80746 8.99001 8.80746V7.60746C8.52492 7.60746 8.06812 7.48438 7.666 7.25072L7.06311 8.28828ZM8.99001 8.80746C9.66686 8.80746 10.3317 8.62834 10.9169 8.28828L10.314 7.25072C9.91189 7.48438 9.45509 7.60746 8.99001 7.60746V8.80746ZM10.9132 8.29041L16.8221 4.91273L16.2266 3.87093L10.3177 7.2486L10.9132 8.29041Z"
                  fill="#0a3041" />
              </svg>
              <Field name="email" type="email" :placeholder="$t('Email')" />
              <ErrorMessage name="email" class="error-message" />
            </div>
            <div class="col-12 field">
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.375 7.71428C2.20924 7.71428 2.05027 7.78201 1.93306 7.90257C1.81585 8.02313 1.75 8.18664 1.75 8.35714V16.0714C1.75 16.2419 1.81585 16.4054 1.93306 16.526C2.05027 16.6466 2.20924 16.7143 2.375 16.7143H13.625C13.7908 16.7143 13.9497 16.6466 14.0669 16.526C14.1842 16.4054 14.25 16.2419 14.25 16.0714V8.35714C14.25 8.18664 14.1842 8.02313 14.0669 7.90257C13.9497 7.78201 13.7908 7.71428 13.625 7.71428H2.375ZM2.375 6.42857H13.625C14.1223 6.42857 14.5992 6.63176 14.9508 6.99344C15.3025 7.35511 15.5 7.84565 15.5 8.35714V16.0714C15.5 16.5829 15.3025 17.0735 14.9508 17.4351C14.5992 17.7968 14.1223 18 13.625 18H2.375C1.87772 18 1.40081 17.7968 1.04917 17.4351C0.697544 17.0735 0.5 16.5829 0.5 16.0714V8.35714C0.5 7.84565 0.697544 7.35511 1.04917 6.99344C1.40081 6.63176 1.87772 6.42857 2.375 6.42857Z"
                  fill="#0a3041" />
                <path
                  d="M8 9.64285C8.16576 9.64285 8.32473 9.71058 8.44194 9.83114C8.55915 9.9517 8.625 10.1152 8.625 10.2857V14.1429C8.625 14.3134 8.55915 14.4769 8.44194 14.5974C8.32473 14.718 8.16576 14.7857 8 14.7857C7.83424 14.7857 7.67527 14.718 7.55806 14.5974C7.44085 14.4769 7.375 14.3134 7.375 14.1429V10.2857C7.375 10.1152 7.44085 9.9517 7.55806 9.83114C7.67527 9.71058 7.83424 9.64285 8 9.64285ZM11.75 6.42857V5.14286C11.75 4.11988 11.3549 3.1388 10.6517 2.41544C9.94839 1.69209 8.99456 1.28571 8 1.28571C7.00544 1.28571 6.05161 1.69209 5.34835 2.41544C4.64509 3.1388 4.25 4.11988 4.25 5.14286V6.42857H11.75ZM8 0C9.32608 0 10.5979 0.541835 11.5355 1.50631C12.4732 2.47078 13 3.77889 13 5.14286V7.71428H3V5.14286C3 3.77889 3.52678 2.47078 4.46447 1.50631C5.40215 0.541835 6.67392 0 8 0Z"
                  fill="#0a3041" />
              </svg>
              <Field name="password" :type="type_password" id="password" :placeholder="$t('Password')" />
              <ErrorMessage name="password" class="error-message" />
              <svg v-if="type_password =='text'" @click="showPassword" class="svg-eye" width="16" height="11" viewBox="0 0 16 11" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 0.0458984C4.36364 0.0458984 1.25818 2.30772 0 5.50044C1.25818 8.69317 4.36364 10.955 8 10.955C11.6364 10.955 14.7418 8.69317 16 5.50044C14.7418 2.30772 11.6364 0.0458984 8 0.0458984ZM8 9.13681C5.99273 9.13681 4.36364 7.50772 4.36364 5.50044C4.36364 3.49317 5.99273 1.86408 8 1.86408C10.0073 1.86408 11.6364 3.49317 11.6364 5.50044C11.6364 7.50772 10.0073 9.13681 8 9.13681ZM8 3.31863C6.79273 3.31863 5.81818 4.29317 5.81818 5.50044C5.81818 6.70772 6.79273 7.68226 8 7.68226C9.20727 7.68226 10.1818 6.70772 10.1818 5.50044C10.1818 4.29317 9.20727 3.31863 8 3.31863Z"
                  fill="#225476" />
              </svg>
              <svg v-else @click="showPassword" class="svg-eye" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="18" height="13" >
                <path fill="#225476"
                  d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z" />
              </svg>
            </div>
            <div class="col-12 field">
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.375 7.71428C2.20924 7.71428 2.05027 7.78201 1.93306 7.90257C1.81585 8.02313 1.75 8.18664 1.75 8.35714V16.0714C1.75 16.2419 1.81585 16.4054 1.93306 16.526C2.05027 16.6466 2.20924 16.7143 2.375 16.7143H13.625C13.7908 16.7143 13.9497 16.6466 14.0669 16.526C14.1842 16.4054 14.25 16.2419 14.25 16.0714V8.35714C14.25 8.18664 14.1842 8.02313 14.0669 7.90257C13.9497 7.78201 13.7908 7.71428 13.625 7.71428H2.375ZM2.375 6.42857H13.625C14.1223 6.42857 14.5992 6.63176 14.9508 6.99344C15.3025 7.35511 15.5 7.84565 15.5 8.35714V16.0714C15.5 16.5829 15.3025 17.0735 14.9508 17.4351C14.5992 17.7968 14.1223 18 13.625 18H2.375C1.87772 18 1.40081 17.7968 1.04917 17.4351C0.697544 17.0735 0.5 16.5829 0.5 16.0714V8.35714C0.5 7.84565 0.697544 7.35511 1.04917 6.99344C1.40081 6.63176 1.87772 6.42857 2.375 6.42857Z"
                  fill="#0a3041" />
                <path
                  d="M8 9.64285C8.16576 9.64285 8.32473 9.71058 8.44194 9.83114C8.55915 9.9517 8.625 10.1152 8.625 10.2857V14.1429C8.625 14.3134 8.55915 14.4769 8.44194 14.5974C8.32473 14.718 8.16576 14.7857 8 14.7857C7.83424 14.7857 7.67527 14.718 7.55806 14.5974C7.44085 14.4769 7.375 14.3134 7.375 14.1429V10.2857C7.375 10.1152 7.44085 9.9517 7.55806 9.83114C7.67527 9.71058 7.83424 9.64285 8 9.64285ZM11.75 6.42857V5.14286C11.75 4.11988 11.3549 3.1388 10.6517 2.41544C9.94839 1.69209 8.99456 1.28571 8 1.28571C7.00544 1.28571 6.05161 1.69209 5.34835 2.41544C4.64509 3.1388 4.25 4.11988 4.25 5.14286V6.42857H11.75ZM8 0C9.32608 0 10.5979 0.541835 11.5355 1.50631C12.4732 2.47078 13 3.77889 13 5.14286V7.71428H3V5.14286C3 3.77889 3.52678 2.47078 4.46447 1.50631C5.40215 0.541835 6.67392 0 8 0Z"
                  fill="#0a3041" />
              </svg>
              <Field name="ConfirmPassword" :type="type_ConfirmPassword" id="showConfirmPassword"
                :placeholder="$t('Confirm_Password')" />
              <ErrorMessage name="ConfirmPassword" class="error-message" />
              <svg  v-if="type_ConfirmPassword =='text'" @click="showConfirmPassword" class="svg-eye" width="16" height="11" viewBox="0 0 16 11" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 0.0458984C4.36364 0.0458984 1.25818 2.30772 0 5.50044C1.25818 8.69317 4.36364 10.955 8 10.955C11.6364 10.955 14.7418 8.69317 16 5.50044C14.7418 2.30772 11.6364 0.0458984 8 0.0458984ZM8 9.13681C5.99273 9.13681 4.36364 7.50772 4.36364 5.50044C4.36364 3.49317 5.99273 1.86408 8 1.86408C10.0073 1.86408 11.6364 3.49317 11.6364 5.50044C11.6364 7.50772 10.0073 9.13681 8 9.13681ZM8 3.31863C6.79273 3.31863 5.81818 4.29317 5.81818 5.50044C5.81818 6.70772 6.79273 7.68226 8 7.68226C9.20727 7.68226 10.1818 6.70772 10.1818 5.50044C10.1818 4.29317 9.20727 3.31863 8 3.31863Z"
                  fill="#225476" />
              </svg>
              <svg v-else @click="showConfirmPassword" class="svg-eye" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="18" height="13" >
                <path fill="#225476"
                  d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z" />
              </svg>
            </div>
            <div class="col-12 d-flex align-items-center">
              <Field class="m-0" type="checkbox" :value="true" :unchecked-value="false" v-model="termsAgreed" id="terms"
                name="terms" />
              <label for="policy">

                <div v-if="this.$i18n.locale == 'ar'">
                  الموافقة على <router-link to="/using-policy">شروط الاستخدام</router-link> و
                  <router-link to="/privacy-policy">سياسة الخصوصية</router-link>
                </div>
                <div v-else>
                  Agree to the <router-link to="/using-policy">terms of use</router-link> and
                  <router-link to="/privacy-policy">privacy policy</router-link>
                </div>


              </label>
            </div>
            <ErrorMessage name="terms" class="error-message" />
            <div class="col-12">
              <button class="btn-main">{{ $t('Register') }}</button>
            </div>
            <!-- <div class="col-12 d-flex align-items-center justify-content-between ">
        <span>
          {{ this.$i18n.locale == 'ar' ? ` لديك حساب  ؟` : `have an account ?` }}
        </span>
        <router-link to="/login">
          {{ $t('Login') }}
        </router-link>
      </div> -->
          </div>
        </Form>
        <Verification @hide="ShowVerification = !ShowVerification" v-if="ShowVerification" :PhoneNumber="PhoneNumber" />
      </div>
    </div>
  </div>
</template>

<script>
import Verification from './Verification.vue';
import axios from 'axios';
import { notify } from "@kyvg/vue3-notification";
// import vee-validate
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { configure } from "vee-validate";
import Cookie from 'cookie-universal'
const cookie = Cookie()
import intlTelInput from "../../intl-tel-input.js"
import Multiselect from 'vue-multiselect';

// FireBase
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDX06BK2NHUkjbCgzUXlrAMm2cLBOPvihI",
  authDomain: "etmaam-1cc0f.firebaseapp.com",
  projectId: "etmaam-1cc0f",
  storageBucket: "etmaam-1cc0f.appspot.com",
  messagingSenderId: "243716425820",
  appId: "1:243716425820:web:6c2757f4129a582630be99",
  measurementId: "G-VYR7TMB41D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

import { getMessaging, getToken } from "firebase/messaging";


export default {
  data() {
    return {
      Max: 12,
      comercial: 1,
      type_password: 'password',
      type_ConfirmPassword: 'password',
      ShowVerification: false,
      PhoneNumber: '',
      FullNumber: '',
      fcm_token: '',
      oneClick: true,
      title:'',
      description:'',
      img:'',
      card_1_title:'',
      card_1_description:'',
      card_1_photo:'',
      card_2_title:'',
      card_2_description:'',
      card_2_photo:'',
      card_3_title:'',
      card_3_description:'',
      card_3_photo:'',
    }
  },
  setup() {
    configure({
      validateOnInput: true,
    });

    const schema = [
      yup.object({
        name: yup.string()
          .required(cookie.get('lang') === 'ar' ? 'حقل الاسم مطلوب' : 'name required')
          .min(2, cookie.get('lang') === 'ar' ? 'يجب أن يكون الاسم من 2 أحرف على الأقل' : 'name must be at least 2 characters'),
        company: yup.string().required(cookie.get('lang') === 'ar' ? 'حقل اسم المنشأة مطلوب' : 'company name required').min(4, cookie.get('lang') === 'ar' ? 'يجب أن يكون الاسم من 4 أحرف على الأقل' : 'name must be at least 4 characters'),
        email: yup.string().required(cookie.get('lang') == 'ar' ? 'حقل البريد الالكتروني مطلوب' : 'email is required').email(cookie.get('lang') == 'ar' ? 'يجب إدخال بريد إلكتروني صالح' : 'email must be valid'),

        password: yup.string()
          .required(cookie.get('lang') === 'ar' ? 'كلمة المرور مطلوبة' : 'Password is required')
          .min(8, cookie.get('lang') === 'ar' ? 'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل' : 'Password must be at least 8 characters')
          .matches(/[A-Z]/, cookie.get('lang') === 'ar' ? 'يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل' : 'Password must contain at least one uppercase letter')
          .matches(/[!@#$%^&*(),.?":{}|<>]/, cookie.get('lang') === 'ar' ? 'يجب أن تحتوي كلمة المرور على رمز واحد على الأقل' : 'Password must contain at least one symbol'),

        ConfirmPassword: yup.string()
          .required(cookie.get('lang') === 'ar' ? 'كلمة المرور  مطلوبة' : 'password is required')
          .oneOf([yup.ref('password')], cookie.get('lang') === 'ar' ? 'يجب ان تكون كلمة المرور مطابقة' : 'password must be valid'),

        terms: yup.bool()
          .required(cookie.get('lang') === 'ar' ? 'يجب القبول لشروط الاستخدام' : 'terms is required'),
      }),
      yup.object({
        name: yup.string()
          .required(cookie.get('lang') === 'ar' ? 'حقل الاسم مطلوب' : 'name required')
          .min(4, cookie.get('lang') === 'ar' ? 'يجب أن يكون الاسم من 4 أحرف على الأقل' : 'name must be at least 4 characters'),
        email: yup.string()
          .required(cookie.get('lang') === 'ar' ? 'حقل البريد الالكتروني مطلوب' : 'email is required')
          .email(cookie.get('lang') === 'ar' ? 'يجب إدخال بريد إلكتروني صالح' : 'email must be valid'),

        password: yup.string()
          .required(cookie.get('lang') === 'ar' ? 'كلمة المرور  مطلوبة' : 'password is required')
          .min(8, cookie.get('lang') === 'ar' ? 'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل' : 'password must be at least 8 characters'),

        ConfirmPassword: yup.string()
          .required(cookie.get('lang') === 'ar' ? 'كلمة المرور  مطلوبة' : 'password is required')
          .oneOf([yup.ref('password')], cookie.get('lang') === 'ar' ? 'يجب ان تكون كلمة المرور مطابقة' : 'password must be valid'),

        terms: yup.bool()
          .required(cookie.get('lang') === 'ar' ? 'يجب القبول لشروط الاستخدام' : 'terms is required'),
      }),
    ]
    return {
      schema,
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Verification,
    Multiselect
  },
  computed: {
    currentSchema() {
      if (this.comercial == 1) {
        return this.schema[0];

      }
      else {
        return this.schema[1];
      }

    }
  },
  methods: {
    handleStaticAbout(StaticContent){
    StaticContent.forEach((item) => {
    if (item.key == 'about_etmaam') {
      this.title = item.value;
    }
    if(item.key == 'about_etmaam_description'){
      this.description = item.value;
    }
    if(item.key == 'about_etmaam_photo'){
      this.img = item.value;
    }
    if(item.key == 'card_1_title'){
      this.card_1_title = item.value;
    }
    if(item.key == 'card_1_description'){
      this.card_1_description = item.value;
    }
    if(item.key == 'card_1_photo'){
      this.card_1_photo = item.value;
    }
    if(item.key == 'card_2_title'){
      this.card_2_title = item.value;
    }
    if(item.key == 'card_2_description'){
      this.card_2_description = item.value;
    }
    if(item.key == 'card_2_photo'){
      this.card_2_photo = item.value;
    }
    if(item.key == 'card_3_title'){
      this.card_3_title = item.value;
    }
    if(item.key == 'card_3_description'){
      this.card_3_description = item.value;
    }
    if(item.key == 'card_3_photo'){
      this.card_3_photo = item.value;
    }
  });
    },
    showPassword() {
      if (this.type_password == 'text') {
        this.type_password = 'password'
      }
      else {
        this.type_password = 'text';
      }
    },
    showConfirmPassword() {
      if (this.type_ConfirmPassword == 'text') {
        this.type_ConfirmPassword = 'password'
      }
      else {
        this.type_ConfirmPassword = 'text';
      }
    },
    HandelSubmit(values) {
      var self = this;
      if (self.oneClick) {
        self.oneClick = false;
        if (!this.termsAgreed) {
          notify({
            type: "error",
            title: cookie.get('lang') == 'ar' ? 'خطأ' : 'Error',
            text: cookie.get('lang') == 'ar' ? 'يجب الموافقة على الشروط والأحكام' : 'You must agree to the terms and conditions',
          });
          return; // منع إرسال النموذج
        }

        var formData = {};
        if (this.comercial == 1) {
          formData = {
            "name": values.name,
            "email": values.email,
            "phone": this.FullNumber,
            "password": values.password,
            "subscribed_latest_news": 1,
            "fcm_token": this.fcm_token != '' ? this.fcm_token : 'test',
            "company_name": values.company,

          }
        }
        else {
          formData = {
            "name": values.name,
            "email": values.email,
            "phone": this.FullNumber,
            "password": values.password,
            "subscribed_latest_news": 1,
            "fcm_token": this.fcm_token != '' ? this.fcm_token : 'test',
          }
        }
        if (this.FullNumber) {
          axios.post(`/user/auth/signup`, formData)
            .then((res) => {
              console.log('res :', res);
              if (res.status == 200) {
                notify({
                  type: "success",
                  text: cookie.get('lang') == 'ar' ? 'تم إرسال رمز التحقق لتأكيد حسابك' : 'A verification code has been sent to confirm your account',
                });
                this.ShowVerification = true;
                this.PhoneNumber = this.FullNumber;
                // axios.post(`/user/auth/request-code`, { "phone": this.FullNumber })
                //   .then((response) => {
                //     self.oneClick = true;
                //     console.log('response :', response);
                //   });
              }
            })
            .catch(function (error) {
              self.oneClick = true;
              notify({
                type: "error",
                title: cookie.get('lang') == 'ar' ? 'تسجيل حساب' : 'Register',
                text: error.response?.data?.message,
              });
            });
        }
      }



    }
  },
  mounted() {
    this.$store.dispatch('GetStaticContent').then(r => {
          this.handleStaticAbout(this.$store.state.StaticContent);
    });
    const output = document.querySelector("#output");
    const inputElement = document.querySelector('#phone');
    const iti = intlTelInput(inputElement, {
      i18n: {
        searchPlaceholder: cookie.get('lang') ? 'بحث' : 'Search',
      },
      initialCountry: "auto",
      nationalMode: true,
      geoIpLookup: callback => {
        fetch("https://ipapi.co/json")
          .then(res => res.json())
          .then(data => callback(data.country_code))
          .catch(() => callback("us"));
      },
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.7.3/build/js/utils.js"
    });

    const handleChange = () => {
      if (iti.selectedCountryData.name != "Saudi Arabia") {
        this.Max = 12;
      }
      else {
        this.Max = 12;
      }
      let text;
      if (cookie.get('lang') == 'ar') {
        if (inputElement.value != '') {
          text = iti.isValidNumber()
            ? ""
            : "رقم غير صحيح - الرجاء اعد المحاولة";
          if (iti.isValidNumber()) {
            this.FullNumber = iti.getNumber();
          }
        } else {
          text = "الرجاء ادخال رقم صحيح";
        }
      }
      else {
        if (inputElement.value != '') {
          text = iti.isValidNumber()
            ? ""
            : "Invalid number - please try again";
          if (iti.isValidNumber()) {
            this.FullNumber = iti.getNumber();
          }
          else {
            this.FullNumber = false;
          }
        } else {
          text = "Please enter a valid number below";
        }
      }
      const textNode = document.createTextNode(text);
      output.innerHTML = "";
      output.appendChild(textNode);
    };

    // listen to "keyup", but also "change" to update when the user selects a country
    inputElement.addEventListener('change', handleChange);
    inputElement.addEventListener('keyup', handleChange);

    // FireBase Get FCM Token
    const messaging = getMessaging();


    getToken(messaging, { vapidKey: 'BKFdBcZLsvy8GqypQJcVx81LsBHVVn5tQIPYw1SlN4N3CqAg4-K0Q5jTlGcT63yV7Fi7g9FRrbewVyXjEjwdBdE' })
      .then((currentToken) => {
        if (currentToken) {
          this.fcm_token = currentToken;
          // Send the token to your server or save it for later use
        } else {
          console.log("No registration token available. Request permission to generate one.");
        }
      })
      .catch((error) => {
        console.error("An error occurred while retrieving FCM token.", error);
      });
    // Notification.requestPermission().then(async (permission) => {
    //   if (permission === "granted") {
    //     // Request permission for notifications
    //     getToken(messaging, { vapidKey: 'BKFdBcZLsvy8GqypQJcVx81LsBHVVn5tQIPYw1SlN4N3CqAg4-K0Q5jTlGcT63yV7Fi7g9FRrbewVyXjEjwdBdE' })
    //       .then((currentToken) => {
    //         if (currentToken) {
    //           console.log("FCM Token:", currentToken);
    //           this.fcm_token = currentToken;
    //           // Send the token to your server or save it for later use
    //         } else {
    //           console.log("No registration token available. Request permission to generate one.");
    //         }
    //       })
    //       .catch((error) => {
    //         console.error("An error occurred while retrieving FCM token.", error);
    //       });
    //   } else {
    //     console.log("Unable to get permission to notify.");
    //   }
    // });



  }
}
</script>
