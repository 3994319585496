<template>
  <div class="bottom-header">
    <div class="row">
      <div class="col-sm-3 col-lg-2 col-6">
        <router-link to="/">
          <img
            width="142px"
            height="49px"
            class="logo"
            src="@/assets/img/global/Logo.webp"
            alt="logo"
          />
          <img class="logo1" src="@/assets/img/global/Logo1.svg" alt="logo" />
        </router-link>
      </div>
      <div class="col-sm-9 col-lg-7 d-none d-md-flex align-items-center">
        <ul class="links-list">
          <li v-for="item in list" :key="item.id">
            <router-link :to="item.url" v-if="!item.sons">
              {{ this.$i18n.locale == "ar" ? item.titleAr : item.titleEn }}
            </router-link>
            <div class="dropdown" v-else>
              <router-link
                class="dropdown-btn"
                :to="item.sons[0].url"
                target="_self"
              >
                {{ this.$i18n.locale == "ar" ? item.titleAr : item.titleEn }}
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 14 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0914 0L7 5.14365L1.90859 0L0 1.92818L7 9L14 1.92818L12.0914 0Z"
                    fill="white"
                  />
                </svg>
              </router-link>
              <ul class="dropdown-lists">
                <li v-for="j in item.sons" :key="j.id">
                  <router-link :to="j.url">
                    {{ this.$i18n.locale == "ar" ? j.titleAr : j.titleEn }}
                  </router-link>
                  <span></span>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-sm-9 col-lg-3 d-none d-md-flex align-items-center">
        <ul class="links-list links-list-not-after justify-content-end">
          <li
            class="nav-item dropdown dropdown-lang"
            @click="changeLanuage(this.$i18n.locale == 'ar' ? 'en' : 'ar')"
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.015 13.5815C13.794 12.9015 13.1565 12.4 12.4 12.4H11.55V9.85C11.55 9.62457 11.4604 9.40837 11.301 9.24896C11.1416 9.08955 10.9254 9 10.7 9H5.6V7.3H7.3C7.52543 7.3 7.74163 7.21045 7.90104 7.05104C8.06045 6.89163 8.15 6.67543 8.15 6.45V4.75H9.85C10.3009 4.75 10.7333 4.57089 11.0521 4.25208C11.3709 3.93327 11.55 3.50087 11.55 3.05V2.7015C12.567 3.11151 13.4695 3.76185 14.1803 4.59686C14.891 5.43187 15.3888 6.42667 15.6311 7.4961C15.8734 8.56554 15.853 9.67777 15.5715 10.7376C15.2901 11.7974 14.756 12.7732 14.015 13.5815ZM8.15 15.7405C4.7925 15.324 2.2 12.468 2.2 9C2.2 8.473 2.268 7.963 2.3785 7.4785L6.45 11.55V12.4C6.45 12.8509 6.62911 13.2833 6.94792 13.6021C7.26673 13.9209 7.69913 14.1 8.15 14.1M9 0.5C7.88376 0.5 6.77846 0.719859 5.74719 1.14702C4.71592 1.57419 3.77889 2.20029 2.98959 2.98959C1.39553 4.58365 0.5 6.74566 0.5 9C0.5 11.2543 1.39553 13.4163 2.98959 15.0104C3.77889 15.7997 4.71592 16.4258 5.74719 16.853C6.77846 17.2801 7.88376 17.5 9 17.5C11.2543 17.5 13.4163 16.6045 15.0104 15.0104C16.6045 13.4163 17.5 11.2543 17.5 9C17.5 7.88376 17.2801 6.77846 16.853 5.74719C16.4258 4.71592 15.7997 3.77889 15.0104 2.98959C14.2211 2.20029 13.2841 1.57419 12.2528 1.14702C11.2215 0.719859 10.1162 0.5 9 0.5Z"
                  fill="white"
                />
              </svg>
              {{ this.$i18n.locale == "ar" ? "English" : "العربية" }}
            </a>
          </li>
          <li v-if="$store.state.Userdata == ''">
            <router-link
              class="login"
              v-if="$route.path != '/login'"
              to="/login"
            >
              <BtnMain
                :title="$t('Login')"
                :TextColor="'#225476'"
                :hovercolor="'#fff'"
                :BackgroundColor="'#fff'"
                :hoverBackgroundColor="'transparent'"
                :Border="'#fff'"
                :hoverBorder="'#fff'"
              />
            </router-link>
            <router-link class="login" v-else to="/register">
              <BtnMain
                :title="$t('Sign Up')"
                :TextColor="'#225476'"
                :hovercolor="'#fff'"
                :BackgroundColor="'#fff'"
                :hoverBackgroundColor="'transparent'"
                :Border="'#fff'"
                :hoverBorder="'#fff'"
              />
            </router-link>
          </li>
          <li v-else class="profile-link" @click="showProfileMenu = !showProfileMenu">
    <img
      style="max-width: 26px"
      class="user-icon"
      :src="$store.state.Userdata.image"
      alt=""
    />
    <span class="name">{{ $store.state.Userdata.name.split(' ')[0] }}</span>
    <ul class="profile-menu" :class="{ active: showProfileMenu }">
      <router-link to="/profile/home">
        <li>
          {{ $t("Company Dashboard") }}
        </li>
      </router-link>
      <router-link to="/profile/companies">
        <li>
          {{ $t("Change Company") }}
        </li>
      </router-link>
      <li @click="logOut()" style="font-weight: 700; font-size: 13px">
        {{ $t("LogOut") }}
      </li>
    </ul>
  </li>
          <li></li>
        </ul>
      </div>
      <div
        class="col-md-4 col-6 d-flex d-md-none align-items-center justify-content-end"
      >
        <div id="mobile-menu">
          <div class="d-flex">
            <span
              class="pointer"
              style="margin-inline-end: 8px"
              @click="changeLanuage(this.$i18n.locale == 'ar' ? 'en' : 'ar')"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.015 13.5815C13.794 12.9015 13.1565 12.4 12.4 12.4H11.55V9.85C11.55 9.62457 11.4604 9.40837 11.301 9.24896C11.1416 9.08955 10.9254 9 10.7 9H5.6V7.3H7.3C7.52543 7.3 7.74163 7.21045 7.90104 7.05104C8.06045 6.89163 8.15 6.67543 8.15 6.45V4.75H9.85C10.3009 4.75 10.7333 4.57089 11.0521 4.25208C11.3709 3.93327 11.55 3.50087 11.55 3.05V2.7015C12.567 3.11151 13.4695 3.76185 14.1803 4.59686C14.891 5.43187 15.3888 6.42667 15.6311 7.4961C15.8734 8.56554 15.853 9.67777 15.5715 10.7376C15.2901 11.7974 14.756 12.7732 14.015 13.5815ZM8.15 15.7405C4.7925 15.324 2.2 12.468 2.2 9C2.2 8.473 2.268 7.963 2.3785 7.4785L6.45 11.55V12.4C6.45 12.8509 6.62911 13.2833 6.94792 13.6021C7.26673 13.9209 7.69913 14.1 8.15 14.1M9 0.5C7.88376 0.5 6.77846 0.719859 5.74719 1.14702C4.71592 1.57419 3.77889 2.20029 2.98959 2.98959C1.39553 4.58365 0.5 6.74566 0.5 9C0.5 11.2543 1.39553 13.4163 2.98959 15.0104C3.77889 15.7997 4.71592 16.4258 5.74719 16.853C6.77846 17.2801 7.88376 17.5 9 17.5C11.2543 17.5 13.4163 16.6045 15.0104 15.0104C16.6045 13.4163 17.5 11.2543 17.5 9C17.5 7.88376 17.2801 6.77846 16.853 5.74719C16.4258 4.71592 15.7997 3.77889 15.0104 2.98959C14.2211 2.20029 13.2841 1.57419 12.2528 1.14702C11.2215 0.719859 10.1162 0.5 9 0.5Z"
                  fill="white"
                />
              </svg>
              <span class="text-white" style="margin-inline-start: 4px">{{
                this.$i18n.locale == "ar" ? "English" : "العربية"
              }}</span>
            </span>
<div
  class="side-icon"
  style="cursor: pointer"
  @click="openSideBar = !openSideBar"
>
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="4" width="24" height="2" fill="white" />
    <rect y="11" width="24" height="2" fill="white" />
    <rect y="18" width="24" height="2" fill="white" />
  </svg>
</div>

          </div>
          <div
            class="overlay"
            @click="openSideBar = false"
            :class="[openSideBar ? 'open' : '']"
          ></div>
          <div class="side-bar" :class="[openSideBar ? 'open' : '']">
            <div class="d-flex justify-content-between align-items-center">
              <div class="icon-bar" @click="openSideBar = !openSideBar">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.6581 1.33776L6.99582 6L11.6581 10.6622C11.79 10.7941 11.8641 10.973 11.8641 11.1595C11.8641 11.3461 11.79 11.525 11.6581 11.6569C11.5262 11.7887 11.3473 11.8628 11.1608 11.8628C10.9742 11.8628 10.7953 11.7887 10.6635 11.6569L6.00121 6.99461L1.33897 11.6569C1.20707 11.7887 1.02819 11.8628 0.841661 11.8628C0.655135 11.8628 0.476248 11.7887 0.344354 11.6569C0.21246 11.525 0.138364 11.3461 0.138364 11.1595C0.138364 10.973 0.212461 10.7941 0.344355 10.6622L5.0066 6L0.344355 1.33776C0.212461 1.20586 0.138364 1.02698 0.138364 0.840451C0.138364 0.653925 0.21246 0.47504 0.344354 0.343146C0.476248 0.211252 0.655134 0.137155 0.841661 0.137154C1.02819 0.137155 1.20707 0.211252 1.33897 0.343145L6.00121 5.00539L10.6635 0.343146C10.7953 0.211252 10.9742 0.137155 11.1608 0.137155C11.3473 0.137155 11.5262 0.211252 11.6581 0.343146C11.79 0.47504 11.8641 0.653925 11.8641 0.840452C11.8641 1.02698 11.79 1.20586 11.6581 1.33776Z"
                    fill="#fff"
                  />
                </svg>
              </div>
              <div class="logo">
                <router-link to="/">
                  <img width="100" src="@/assets/img/global/Logo.webp" alt="logo" />
                </router-link>
              </div>
            </div>
            <div class="side-links">
              <ul>
                <li v-for="item in list" :key="item.id">
                  <router-link :to="item.url" v-if="!item.sons">
                    {{
                      this.$i18n.locale == "ar" ? item.titleAr : item.titleEn
                    }}
                  </router-link>
                  <div class="dropdown" v-else>
                    <button
                      class="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {{
                        this.$i18n.locale == "ar" ? item.titleAr : item.titleEn
                      }}
                    </button>
                    <ul class="dropdown-menu">
                      <li v-for="j in item.sons" :key="j.id">
                        <router-link class="dropdown-item" :to="j.url">
                          {{
                            this.$i18n.locale == "ar" ? j.titleAr : j.titleEn
                          }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li v-if="$store.state.Userdata == ''">
                  <router-link to="/login">
                    <BtnMain
                      :title="$t('Login')"
                      :TextColor="'#225476'"
                      :hovercolor="'#fff'"
                      :BackgroundColor="'#fff'"
                      :hoverBackgroundColor="'transparent'"
                      :Border="'#fff'"
                      :hoverBorder="'#fff'"
                    />
                  </router-link>
                </li>
                <li v-else>
                  <router-link to="/profile/home">
                    {{ $t("Company Dashboard") }}
                  </router-link>
                </li>
                <li
                  v-if="$store.state.Userdata != ''"
                  @click="logOut()"
                  class="log-out"
                >
                  {{ $t("LogOut") }}
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="mobile-list" :class="[openSideBar ? 'open':'']">
               <ul>
                  <li v-for="item in list" :key="item.id">
                     <router-link :to="item.url" v-if="!item.sons">
                        {{ this.$i18n.locale == 'ar' ? item.titleAr : item.titleEn }}
                     </router-link>
                     <div class="dropdown" v-else>
                  <a class="dropdown-btn" href="#" target="_self">
                     {{ this.$i18n.locale == 'ar' ? item.titleAr : item.titleEn }}
                     <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.0914 0L7 5.14365L1.90859 0L0 1.92818L7 9L14 1.92818L12.0914 0Z" fill="white"/>
                     </svg>
                  </a>
                  <ul class="dropdown-lists" >
                     <li v-for="j in item.sons" :key="j.id">
                        <router-link :to="j.url">
                           {{ this.$i18n.locale == 'ar' ? j.titleAr : j.titleEn }}
                        </router-link>
                        <span></span>
                     </li>
                  </ul>
               </div>
                  </li>
               </ul>
            </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import Cookie from "cookie-universal";
const cookie = Cookie();
export default {
  data() {
    return {
      showProfileMenu: false,
      openSideBar: false,
      list: [
        {
          id: 1,
          titleEn: "Home",
          titleAr: "الصفحة الرئيسية",
          url: "/",
        },
        {
          id: 2,
          titleEn: "About Us",
          titleAr: "من نحن",
          url: "/",
          sons: [
            {
              id: "son1",
              titleAr: "نبذة عنا",
              titleEn: "About Us",
              url: "/about",
            },
            // {
            //    id: 'son2',
            //    titleAr: 'آخر الأخبار',
            //    titleEn: 'Latest News',
            // },
            // {
            //    id: 'son3',
            //    titleAr: 'دليل الخدمات',
            //    titleEn: 'Service Guide',
            // },
            {
              id: "son3",
              titleAr: "حساباتنا البنكية",
              titleEn: "Accounts Banks",
              url: "/banks",
            },
            {
              id: "son4",
              titleAr: "سياسة الخصوصية",
              titleEn: "Privacy Policy",
              url: "/privacy-policy",
            },
            {
              id: "son5",
              titleAr: "الشروط  و الأحكام",
              titleEn: "Terms And Conditions",
              url: "/using-policy",
            },
            {
              id: "son6",
              titleAr: "انضم إلينا",
              titleEn: "Join Us",
              url: "/join-us",
            },
          ],
        },
        {
          id: 3,
          titleEn: "Blogs",
          titleAr: "المدونة",
          url: "/blogs",
        }, {
          id: 4,
          titleEn: "Services",
          titleAr: "الخدمات",
          url: "/services",
        },


        {
          id: 5,
          titleEn: "Packages",
          titleAr: "الباقات",
          url: "/packages",
        },


        {
          id: 6,
          titleEn: "Waht We Offer",
          titleAr: "ماذا نقدم لكم",
          url: "",
          sons: [
            {
              id: "son1",
              titleAr: "تأسيس الشركات",
              titleEn: "Establishing companies",
              url: "/services/company-formation",
            },
            {
              id: "son2",
              titleAr: "خدمات المستثمرين",
              titleEn: "Investor services",
              url: "/services/foreign-services",
            },
            {
              id: "son3",
              titleAr: "الخدمات القانونية",
              titleEn: "Legal services",
              url: "/services/legal-services",
            },
            {
              id: "son4",
              titleAr: "برنامج حماية الأجور",
              titleEn: "Wage Protection Program",
              url: "/services/wage-protection",
            },
            {
              id: "son5",
              titleAr: "تسجيل العلامات التجارية",
              titleEn: "Trademark registration",
              url: "/services/trademark-registration",
            },
            {
              id: "son6",
              titleAr: "إدارة الخدمات الإلكترونية",
              titleEn: "Electronic services management",
              url: "/services/electronic-services",
            },
          ],
        },
        // {
        //   id: 7,
        //   titleEn: "Join Us",
        //   titleAr: "انضم إلينا",
        //   url: "/join-us",
        // },
        {
          id: 7,
          titleEn: "Help Center",
          titleAr: "مركز المساعدة",
          url: "",
          sons: [
            {
              id: "son1",
              titleEn: "Contact",
              titleAr: "اتصل بنا",
              url: "/contact",
            },
            {
              id: "son2",
              titleAr: "الأسئلة الشائعة",
              titleEn: "FAQ",
              url: "/faqs",
            },
            {
              id: "son3",
              titleAr: "الوظائف",
              titleEn: "Careers",
              url: "/jobs",
            },
            {
              id: "son3",
              titleAr: "مركز التحميلات",
              titleEn: "Downloads Center",
              url: "/downloads-center",
            },
            {
              id: "son4",
              titleAr: "الامتياز التجاري",
              titleEn: "Commercial franchise",
              url: "/commercial-franchise",
            },
          ],
        }
      ],
    };
  },
  components: {
    BtnMain: defineAsyncComponent(() =>
      import("@/components/Global/BtnMain.vue")
    ),
  },
  methods: {
    changeLanuage(lang) {
      this.$store.commit("SET_Loader", true);
      this.$store.commit("SET_LANG", lang);
      this.$i18n.locale = lang;
      cookie.set("lang", lang);
      this.$router.go(0);
    },
    logOut() {
      cookie.remove("Token");
      cookie.remove("Userdata");
      cookie.remove("Companydata");
      cookie.remove("CompanyToken");
      this.$router.go();
    },
  },
  watch: {
    $route(to, from) {
      this.openSideBar = false;
    },
  },
};
</script>
