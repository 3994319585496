<template>
    <div class="packages" data-aos="fade-right">
      <div class="row">
        <div class="col-md-6 packages-img">
            <img class="img-left" data-aos="fade-right" src="@/assets/img/home-page/Packages-left.webp" alt="package left" loading="lazy">
            <img class="img-center" data-aos="fade-down" src="@/assets/img/home-page/Packages-center.webp" alt="package center" loading="lazy">
            <img class="img-right" data-aos="fade-left" src="@/assets/img/home-page/Packages-right.webp" alt="package right" loading="lazy">
        </div>
        <div class="col-md-6 guide">
            <h1>{{ this.$i18n.locale == 'ar' ? titleAr1 : titleEn1 }}</h1>
            <p>{{ this.$i18n.locale == 'ar' ? DiscriptionAr : DiscriptionEn }}</p>
            <p>{{ this.$i18n.locale == 'ar' ? DiscriptionAr2 : DiscriptionEn2 }}</p>
            <router-link to="/packages">
              <BtnMain
            :title="$t('BrowseMore')"
            :TextColor="'#fff'"
            :hovercolor="'#225476'"
            :BackgroundColor="'transparent'"
            :hoverBackgroundColor="'#fff'"
            :Border="'#fff'"
            :hoverBorder="'#fff'" />
            </router-link>
        </div>
      </div>
    </div>
  </template>

<script>
  import { defineAsyncComponent } from 'vue'
  export default {
    data() {
      return {
        titleAr1: 'اختر باقة الاشتراك التي تناسب اهتماماتك ',
        titleEn1: 'Choose the subscription package that suits your interests',
        DiscriptionAr: 'احصل على فرصة الوصول إلى محتوى حصري ومميز مع باقات الاشتراك السنوية.',
        DiscriptionEn:'Get the opportunity to access exclusive and premium content with annual subscription packages.',
        DiscriptionAr2:'اختر باقة الاشتراك التي تناسب اهتماماتك وتمتع بتجربة استثنائية.',
        DiscriptionEn2:'Choose the subscription package that suits your interests and enjoy an exceptional experience.',

      };
    },
    components: {
      BtnMain: defineAsyncComponent( () => import('@/components/Global/BtnMain.vue') ),
   },
  }
</script>
