<template>
  <div class="step1">
    <div class="title">
      {{
        this.$i18n.locale == `ar` ?
          `الرجاء قراء العقد والشروط والاحكام الخاصة بالباقة للموافقه عليها واستكمال الطلب`
          :
          `Please read the contract and terms and conditions of the package to agree to it and complete the application`
      }}
    </div>
    <div class="contain">
      <h2>
        {{
          this.$i18n.locale == `ar` ?
            `عقد تقديم خدمات باقة ${this.PackagesDetails.name}`
            :
            `Please reaContract to provide Entrepreneur Plus Package services`
        }}
      </h2>
      <p>
        {{
          this.$i18n.locale == `ar` ?
              `مسمى الخدمة : ` + this.PackagesDetails.name
            :
            `Name of the service: contract for the implementation of services`
        }}
      </p>
      <p>
  {{
    this.$i18n.locale == `ar`
      ? `بعون الله في هذا اليوم: ${new Date().toLocaleDateString('ar-EG', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })} تم إبرام هذا العقد بين كلًا من:`
      : `With God’s help, on this day: ${new Date().toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}, this contract was concluded between:`
  }}
</p>
      <strong>
        {{
          this.$i18n.locale == `ar` ?
            `الطرف الأول:`
            :
            `the first side:`
        }}
      </strong>
      <p>{{
          this.$i18n.locale == `ar` ?
            `شركة إتمام الإنجاز لخدمات الأعمال`
            :
            `Etmaam for services`
        }}</p>
      <br>
      <strong>
        {{
          this.$i18n.locale == `ar` ?
            `الطرف الثاني:`
            :
            `Second Party:`
        }}
      </strong>
      <p>
  {{
    this.$i18n.locale === 'ar'
      ? `الطرف الثاني السادة/ ${this.Company.name} ويمثله في توقيع العقد السيد/ة ${this.User.name}:
         • رقم السجل التجاري: ${this.Company.commercial_register || 'لا يوجد'}
         • الرقم الضريبي:  ${this.Company.tax_number || 'لا يوجد'}
         • رقم الهوية: `+ this.User.identity_card_number +`
         • العنوان الوطني: لا يوجد
         • رقم الجوال: ${this.User.phone}
         • البريد الإلكتروني: ${this.User.email}
         ويشار إليه بالعقد بالطرف الثاني.`
      : `The second party, Messrs. ${this.Company.name}, is represented in signing the contract by ${this.User.name}:
         • Commercial registration number: `+ this.Company.commercial_register +`
         • Tax number: ${this.Company.tax_number}
         • ID number: ${this.User.identity_card_number}
         • National address: Not found
         • Mobile number: ${this.User.phone}
         • Email: ${this.User.email}
         Referred to in the contract as the second party.`
  }}
</p>
      <br>
      <strong> {{ this.$i18n.locale == `ar` ? `التمهيد:` : `Preface:` }}</strong>
      <p> {{ this.$i18n.locale == `ar` ? `نظرا لما لدى الطرف الأول من الخبرة والمعرفة في مجال الخدمات المذكورة في البند
        الثالث ، ورغبة الطرف الثاني بالانتفاع من خدمات الطرف الأول بما لديه من الخبرات العلمية والعملية في تقديم الخدمات
        المتعلقة وذلك حسب المعايير والأنظمة المعمول بها في المملكة العربية السعودية فقد أتفق الطرفان وهما بكامل أهليتهما
        المعتبرة شرعا ونظاما للتعاقد على البنود التالية :` : `In view of the experience and knowledge that the first
        party
        has in the field of services mentioned in the third clause, and the desire of the second party to benefit from
        the
        services of the first party, with its scientific and practical experience in providing related services, in
        accordance with the standards and regulations in force in the Kingdom of Saudi Arabia, the two parties have
        agreed, in full capacity Legally and systematically considered for contracting the following items:` }}</p>
      <br>
      <strong>{{ this.$i18n.locale == `ar` ? `البند الأول:` : `First item:` }}</strong>
      <p> {{ this.$i18n.locale == `ar` ? `يعتبر التمهيد السابق والملاحق بهذا العقد جزءا لا يتجزأ منه كما لها نفس الحجية
        والاعتبارات وتشمل هذه الملاحق من أي نوع يتم التوقيع عليها من قبل طرفي هذا العقد.` : `The previous preamble and
        the
        annexes to this contract are considered an integral part of it and have the same validity and considerations.
        These include any annexes of any kind signed by both parties to this contract.` }}</p>
      <br>
      <strong>{{ this.$i18n.locale == `ar` ? `البند الثاني (محل العقد):` : `The second clause (the subject of the
        contract):` }}</strong>
      <p>{{ this.$i18n.locale == `ar` ? `قيام الطرف الأول بتقديم خدمة `+this.PackagesDetails.name+` للطرف الثاني وتقديمها عن
        طريق الموقع الإلكتروني لمنصة إتمام (www.etmaam.com.sa) والمرتبط بتقديم الخدمة المذكورة بالبند ثالثا.` : `The first
        party provides the service `+this.PackagesDetails.name+` to the second party and provides it through the website
        of
        the Igrar platform (www.etmaam.com.sa), which is linked to providing the service mentioned in Clause Three.` }}</p>
      <br>
      <strong>{{ this.$i18n.locale == `ar` ? `البند الثالث وصف الخدمة:` : `The third item is description of the
        service: `+this.FormattedServices+`` 
        }}</strong>
      <p> {{ this.$i18n.locale == `ar` ? `الباقة: `+this.PackagesDetails.name+` وصف الخدمة: تقديم خدمات `+this.FormattedServices+`` : `Package:
        Entrepreneurs Plus Package Service Description: Providing bank reconciliation services, recording daily entries,
        closing accounts, monthly reports, preparing and reviewing tax returns - quarterly.` }}</p>
      <br>
      <p>
        {{ 
          this.$i18n.locale == `ar`?`•	والموضح تفاصيلها في النافذة اليسرى من الصفحة عند الضغط على كل قسم من التصنيفات المذكورة سلفاً`:`As decleard in the left tab`
         }}
      </p>
      <p>
        {{ 
          this.$i18n.locale == `ar`?`•	الخدمات المقدمة لا تشمل الرسوم الحكومية المرتبطة بأي من الإجراءات أو الترتيبات، كما أن الطرف الأول لا يتحمل أي مسؤولية فيما يتعلق بإتمام تلك الرسوم أو تنفيذ الالتزامات المرتبطة بها. يُرجى مراعاة ذلك عند الاستفادة من الخدمات`:`• The services provided do not include government fees associated with any procedures or arrangements, and the first party does not bear any responsibility with regard to completing those fees or implementing the obligations associated with them. Please keep this in mind when using the Services`
         }}
      </p>
      <!-- <strong>{{ this.$i18n.locale == `ar` ? `البند الرابع (مدة العقد):` : `Clause Four (Contract Duration):`
        }}</strong> -->
      <!-- <p> {{ this.$i18n.locale == `ar` ? `عدد ( 3 ) شهر / اشهر ميلادية تبدا من تاريخ العقد ويتجدد العقد تلقائيا ما لم
        يخطر
        أحد الأطراف الآخر قبل انتهاء مدة العقد بـ 30 يوماً.` : `The number of (3) calendar months starts from the date
        of
        the contract, and the contract is automatically renewed unless one of the parties notifies the other 30 days
        before the end of the contract period.` }}</p> -->
    </div>
    <label class="agree" for="terms" style="cursor: pointer;">
      <input type="checkbox" id="terms" v-model="agree" @keydown="handleKeydown"  @change="HandelAgree">
      <span>
        {{ this.$i18n.locale == 'ar' ? 'الرجاء الموافقة على الشروط و الاحكام للاسئناف' :'Please agree to the terms and conditions to appeal' }}
      </span>
    </label>
  </div>
</template>
<script>
import Cookie from 'cookie-universal'
const cookie = Cookie()
export default {
  data() {
    return {
      agree:false,
      Company: cookie.get('Companydata'),
      User:cookie.get('Userdata'),
      FormattedServices:[],
      PackagesDetails:[]
    }
  },
methods:{
  handleKeydown(event) {
          if (event.key === 'Tab') {
            event.preventDefault(); // Prevent the default tab action
          }
        },
  HandelAgree(){
    this.$store.state.packageOrder.step1 = this.agree;
  }
},

created(){
  let packageId = this.$route.params.id;
        this.$store.dispatch('PackagesDetails', packageId).then(r => {
            this.PackagesDetails = this.$store.state.PackagesDetails;
            let counter = 0;
        
   this.PackagesDetails.services.forEach(
    item=>{
      item.services.forEach(service=>{
        counter++
        
        this.FormattedServices[counter] = `${service.title}\n`
      })
  }
) //      
        });
}
};
</script>