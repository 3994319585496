<template>
    <div id="preloader">
        <div class="loader revolve">
            <div id="wppu-object-wrapper" class="lifebeauty" style="width:500px;height:500px;padding:13%;">
                <div class="wppu-object-logo">
                    <img v-if="lang == 'ar'" src="@/assets/img/global/loading.png" class="officallogo" alt="loading">
                    <img v-else src="@/assets/img/global/loading-en.png" class="officallogo" alt="loading english">
                </div>
                <div class="wpppu-object-wrap" style="color:#235577;"></div>
            </div>
        </div>
    </div>
</template>
<script>
import Cookie from 'cookie-universal'
const cookie = Cookie()
export default {
data(){
    return{
        lang : cookie.get('lang'),
    }
}
}
</script>
