<template>
  <div class="m-p">
    <individial_requests />
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'Required services',
components: {
individial_requests: defineAsyncComponent( () => import('@/components/Profile/IndividialRequests.vue') ),
},
}
</script>
