<template>
  <NewConsulting v-if="this.$store.state.consulting == true" />

  <div class="consulting required-item companies">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="title-profile mt-0">{{ $route.name }}</h2>
        <div>
          <button class="btn-main mx-2" @click="toggleInvitation">
            <svg
              width="16"
              height="19"
              viewBox="0 0 16 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 10.5435C10.3932 10.5435 12.3333 8.51903 12.3333 6.02174C12.3333 3.52445 10.3932 1.5 8 1.5C5.60677 1.5 3.66667 3.52445 3.66667 6.02174C3.66667 8.51903 5.60677 10.5435 8 10.5435ZM8 10.5435C3.66667 10.5435 1 14.0217 1 17.5M8 10.5435C12.3333 10.5435 15 14.0217 15 17.5"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>{{ $t("Invitation Employee") }}</span>
          </button>
          <InvitationEmployee
            v-if="ShowInvitationEmployee"
            @hide="toggleInvitation"
          />
          <button class="btn-main secondary" @click="toggleAddEmployee">
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.89474 13.3947C5.89474 13.6879 6.01118 13.969 6.21846 14.1763C6.42574 14.3836 6.70687 14.5 7 14.5C7.29313 14.5 7.57426 14.3836 7.78154 14.1763C7.98882 13.969 8.10526 13.6879 8.10526 13.3947V8.60526H12.8947C13.1879 8.60526 13.469 8.48882 13.6763 8.28154C13.8836 8.07426 14 7.79313 14 7.5C14 7.20687 13.8836 6.92574 13.6763 6.71846C13.469 6.51118 13.1879 6.39474 12.8947 6.39474H8.10526V1.60526C8.10526 1.31213 7.98882 1.031 7.78154 0.823724C7.57426 0.616447 7.29313 0.5 7 0.5C6.70687 0.5 6.42574 0.616447 6.21846 0.823724C6.01118 1.031 5.89474 1.31213 5.89474 1.60526V6.39474H1.10526C0.812129 6.39474 0.531001 6.51118 0.323724 6.71846C0.116447 6.92574 0 7.20687 0 7.5C0 7.79313 0.116447 8.07426 0.323724 8.28154C0.531001 8.48882 0.812129 8.60526 1.10526 8.60526H5.89474V13.3947Z"
                fill="white"
              />
            </svg>
            <span>{{ $t("Add Employee") }}</span>
          </button>
          <AddEmployee v-if="ShowAddEmployee" @hide="toggleAddEmployee" />
        </div>
      </div>

      <div class="col-12">
        <ul class="nav nav-pills" id="pills-tab3" role="tablist">
          <li class="nav-item active" role="presentation">
            <button
              data-index="Packages1"
              class="nav-link py-3 active"
              id="v-pills-Packages1-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-Packages1"
              type="button"
              role="tab"
              aria-controls="v-pills-Packages1"
              aria-selected="true"
            >
              <span class="fs-sm-4">{{ $t("Company Employees") }}</span>
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              data-index="Packages2"
              class="nav-link py-3"
              id="v-pills-Packages2-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-Packages2"
              type="button"
              role="tab"
              aria-controls="v-pills-Packages2"
              aria-selected="true"
            >
              <span class="fs-sm-4">{{ $t("Employment Invitations") }}</span>
            </button>
          </li>
        </ul>
        <div class="tab-content mt-3" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-Packages1"
            role="tabpanel"
            aria-labelledby="v-pills-Packages1-tab"
          >
            <div class="row">
              <div
                class="head-section-profile justify-content-start align-items-center"
              >
                <div class="search col-md-4 mb-3">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.92 13.92L17 17M16.111 8.581C16.111 12.768 12.728 16.162 8.556 16.162C4.383 16.162 1 12.768 1 8.582C1 4.393 4.383 1 8.555 1C12.728 1 16.111 4.394 16.111 8.581Z"
                      stroke="#225476"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <input
                    @input="FilterSearch"
                    v-model="Search"
                    type="text"
                    :placeholder="$t('Search')"
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <select class="bg-white mb-0 w-100" @change="FilterByCity"  v-model="selectedCity">
                    <option value="" disabled hidden>
                      {{
                        this.$i18n.locale == "ar"
                          ? `التصنيف حسب المدينة..`
                          : `Filter
                                            By City...`
                      }}
                    </option>
                    <option v-for="item in cities" :value="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="consulting-tabel">
                <div class="row head-tabel">
                  <div class="col" style="margin-right: 50px;">
                    {{ $t("Employee Name") }}
                  </div>
                  <div class="col" style="margin-right: 50px;">
                    {{ $t("Email") }}
                  </div>
                  <div class="col" style="margin-right: 50px;">
                    {{ $t("PhoneNumber") }}
                  </div>
                  <div class="col" style="margin-right: 50px;">
                    {{ $t("City") }}
                  </div>
                  <div class="col" style="margin-right: 50px;">
                    {{ $t("Role") }}
                  </div>
                  <div class="col" style="margin-right: 50px;">
                    {{ $t("Management") }}
                  </div>
                </div>
                <div
                  class="row body-tabel"
                  v-if="Employees.length > 0"
                  v-for="(item, index) of Employees"
                  :key="index"
                >
                <div class="col" style="margin-right: 50px;">
                    {{ item.name ? item.name:"_____"}}
                  </div>
                  <div class="col" style="margin-right: 50px;">
                    {{ item.email ? item.email:"_____"}}
                  </div>
                  <div class="col" style="margin-right: 50px;"  :dir="$i18n.locale === 'ar' ? 'ltr' : 'rtl'"   :style="{ textAlign: $i18n.locale === 'ar' ? 'right' : 'left' }"
                  >
                    {{ item.phone ? item.phone : "_____" }}
                  </div>
                  <div class="col" style="margin-right: 50px;">
                    {{ item.city ? item.city.name[this.$i18n.locale] :"_____" }}
                  </div>
                  <div class="col" style="margin-right: 50px;">
                    {{
                      item.role
                        ? item.role.show_name
                        : "_____"
                    }}
                  </div>
                  <div class="col position-relative">
                    <svg
                      @click="ShowSetting = index"
                      style="cursor: pointer; width: 40px"
                      width="4"
                      height="14"
                      viewBox="0 0 4 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 3.40625C2.82843 3.40625 3.5 2.73468 3.5 1.90625C3.5 1.07782 2.82843 0.40625 2 0.40625C1.17157 0.40625 0.5 1.07782 0.5 1.90625C0.5 2.73468 1.17157 3.40625 2 3.40625Z"
                        fill="#225476"
                      />
                      <path
                        d="M2 8.51562C2.82843 8.51562 3.5 7.84405 3.5 7.01562C3.5 6.1872 2.82843 5.51562 2 5.51562C1.17157 5.51562 0.5 6.1872 0.5 7.01562C0.5 7.84405 1.17157 8.51562 2 8.51562Z"
                        fill="#225476"
                      />
                      <path
                        d="M2 13.5937C2.82843 13.5937 3.5 12.9222 3.5 12.0937C3.5 11.2653 2.82843 10.5938 2 10.5938C1.17157 10.5938 0.5 11.2653 0.5 12.0937C0.5 12.9222 1.17157 13.5937 2 13.5937Z"
                        fill="#225476"
                      />
                    </svg>
                    <ul class="setting" v-if="ShowSetting == index">
                      <li @click="toggleRoleUpdate(item)">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.3649 9.8479V13.2079C13.3647 13.6834 13.1757 14.1392 12.8395 14.4754C12.5034 14.8116 12.0475 15.0005 11.5721 15.0007H2.79281C2.55652 15.0006 2.32257 14.9538 2.10441 14.863C1.88625 14.7723 1.68818 14.6392 1.52159 14.4717C1.35499 14.3041 1.22316 14.1052 1.13365 13.8866C1.04415 13.6679 0.998736 13.4336 1.00003 13.1973V4.42793C0.998626 4.192 1.04406 3.95815 1.13369 3.73991C1.22333 3.52167 1.35539 3.3234 1.52221 3.15657C1.68904 2.98974 1.88732 2.85769 2.10555 2.76805C2.32379 2.67841 2.55765 2.63298 2.79357 2.63439H6.15286M13.3649 5.72654L10.2735 2.63439M4.09067 10.8786V9.24021C4.09218 8.97005 4.19964 8.71047 4.38959 8.51901L11.6031 1.30551C11.6988 1.20872 11.8128 1.13188 11.9384 1.07944C12.064 1.027 12.1988 1 12.3349 1C12.471 1 12.6058 1.027 12.7314 1.07944C12.857 1.13188 12.971 1.20872 13.0667 1.30551L14.6945 2.93331C14.7913 3.02903 14.8681 3.14299 14.9206 3.2686C14.973 3.39422 15 3.52899 15 3.66511C15 3.80123 14.973 3.936 14.9206 4.06161C14.8681 4.18722 14.7913 4.30119 14.6945 4.3969L7.48099 11.6104C7.28917 11.8009 7.03012 11.9083 6.75979 11.9093H5.12139C4.98597 11.9095 4.85185 11.883 4.72671 11.8313C4.60157 11.7795 4.48787 11.7036 4.39211 11.6079C4.29636 11.5121 4.22045 11.3984 4.16872 11.2733C4.11699 11.1481 4.09047 11.014 4.09067 10.8786Z"
                            stroke="#276491"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <span>{{ $t(`Edit Employee's Role`) }}</span>
                      </li>
                      <li @click="DeleteEmployee(item.id)">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 1.6C4 1.17565 4.16857 0.768687 4.46863 0.468629C4.76869 0.168571 5.17565 0 5.6 0H10.4C10.8243 0 11.2313 0.168571 11.5314 0.468629C11.8314 0.768687 12 1.17565 12 1.6V3.2H15.2C15.4122 3.2 15.6157 3.28429 15.7657 3.43431C15.9157 3.58434 16 3.78783 16 4C16 4.21217 15.9157 4.41566 15.7657 4.56569C15.6157 4.71571 15.4122 4.8 15.2 4.8H14.3448L13.6512 14.5136C13.6225 14.9173 13.4418 15.2951 13.1457 15.5709C12.8495 15.8467 12.4599 16 12.0552 16H3.944C3.53931 16 3.14965 15.8467 2.85351 15.5709C2.55736 15.2951 2.37673 14.9173 2.348 14.5136L1.656 4.8H0.8C0.587827 4.8 0.384344 4.71571 0.234315 4.56569C0.0842854 4.41566 0 4.21217 0 4C0 3.78783 0.0842854 3.58434 0.234315 3.43431C0.384344 3.28429 0.587827 3.2 0.8 3.2H4V1.6ZM5.6 3.2H10.4V1.6H5.6V3.2ZM3.2592 4.8L3.9448 14.4H12.056L12.7416 4.8H3.2592ZM6.4 6.4C6.61217 6.4 6.81566 6.48429 6.96569 6.63431C7.11571 6.78434 7.2 6.98783 7.2 7.2V12C7.2 12.2122 7.11571 12.4157 6.96569 12.5657C6.81566 12.7157 6.61217 12.8 6.4 12.8C6.18783 12.8 5.98434 12.7157 5.83431 12.5657C5.68429 12.4157 5.6 12.2122 5.6 12V7.2C5.6 6.98783 5.68429 6.78434 5.83431 6.63431C5.98434 6.48429 6.18783 6.4 6.4 6.4ZM9.6 6.4C9.81217 6.4 10.0157 6.48429 10.1657 6.63431C10.3157 6.78434 10.4 6.98783 10.4 7.2V12C10.4 12.2122 10.3157 12.4157 10.1657 12.5657C10.0157 12.7157 9.81217 12.8 9.6 12.8C9.38783 12.8 9.18434 12.7157 9.03431 12.5657C8.88429 12.4157 8.8 12.2122 8.8 12V7.2C8.8 6.98783 8.88429 6.78434 9.03431 6.63431C9.18434 6.48429 9.38783 6.4 9.6 6.4Z"
                            fill="#E24B56"
                          />
                        </svg>
                        <span>{{ $t(`Delete Employee`) }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <NoDataToShow v-else />
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade show"
            id="v-pills-Packages2"
            role="tabpanel"
            aria-labelledby="v-pills-Packages2-tab"
          >
            <div class="row">
              <div class="head-section-profile">
                <div class="search">
                  <!-- <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13.92 13.92L17 17M16.111 8.581C16.111 12.768 12.728 16.162 8.556 16.162C4.383 16.162 1 12.768 1 8.582C1 4.393 4.383 1 8.555 1C12.728 1 16.111 4.394 16.111 8.581Z"
                                            stroke="#225476" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                    <input type="text" placeholder="ابحث باسم الاستشارة..."> -->
                </div>
              </div>
              <div class="consulting-tabel">
                <div class="row head-tabel">

                  <div class="col" style="margin-right: 50px;">
                    {{ $t("Email") }}
                  </div>
                  <div class="col" style="margin-right: 50px;">
                    {{ $t("Role") }}
                  </div>
                  <div class="col" style="margin-right: 50px;">
                    {{ $t("Invitation Status") }}
                  </div>
                  <div class="col" style="margin-right: 50px;">
                    {{ $t("Invitation Date") }}
                  </div>
                </div>
                <div
                  class="row body-tabel"
                  v-if="Invitations.length > 0"
                  v-for="(item, index) in Invitations"
                  :key="index"
                >
                  <div class="col" style="margin-right: 50px;">
                    {{ item.email }}
                  </div>
                  <div class="col" style="margin-right: 50px;">
                    {{ this.$i18n.locale === 'ar' ? item.role_show_name.ar : item.role_show_name.en }}
                  </div>
                  <div class="col" style="margin-right: 50px;">
                    {{ item.status }}
                  </div>
                  <div class="col" style="margin-right: 50px;">
                    {{ item.created_at }}
                  </div>
                </div>
                <NoDataToShow v-else />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RoleUpdate
      :EmployeeObj="EmployeeObj"
      v-if="ShowRoleUpdate"
      @hide="toggleRoleUpdate"
    />
    <Spinner />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import axios from "axios";
import { notify } from "@kyvg/vue3-notification";
import Cookie from "cookie-universal";
const cookie = Cookie();
export default {
  data() {
    return {
      ShowInvitationEmployee: false,
      ShowAddEmployee: false,
      ShowRoleUpdate: false,
      ShowSetting: -1,
      index: 1,
      Employees: [],
      Invitations: [],
      cities: [],
      EmployeeObj: {},
      Search: "",
      selectedCity:""
    };
  },
  components: {
    InvitationEmployee: defineAsyncComponent(() =>
      import("@/components/Profile/Companies/Modals/InvitationEmployee.vue")
    ),
    AddEmployee: defineAsyncComponent(() =>
      import("@/components/Profile/Companies/Modals/AddEmployee.vue")
    ),
    RoleUpdate: defineAsyncComponent(() =>
      import("@/components/Profile/Companies/Modals/RoleUpdate.vue")
    ),
    NoDataToShow: defineAsyncComponent(() =>
      import("@/components/Global/NoDataToShow.vue")
    ),
    Spinner: defineAsyncComponent(() =>
      import("@/components/Global/Spinner.vue")
    ),
  },
  methods: {
    toggleInvitation() {
      this.ShowInvitationEmployee = !this.ShowInvitationEmployee;
    },
    toggleAddEmployee() {
      this.ShowAddEmployee = !this.ShowAddEmployee;
    },
    toggleRoleUpdate(item) {
      this.EmployeeObj = item;
      this.ShowRoleUpdate = !this.ShowRoleUpdate;
      this.ShowSetting = -1;
    },
    DeleteEmployee(id) {
      this.ShowSetting = -1;
      axios
        .delete(`/company/dashboard/employees/${id}`, {
          headers: {
            Authorization: `Bearer ${cookie.get("CompanyToken")}`,
          },
        })
        .then((res) => {
          notify({
            type: "succes",
            text:
              this.$i18n.locale == "ar"
                ? "تم ارسال الطلب بنجاح"
                : "Send Employee Invitation Success",
          });
          this.GetEmployees();
        })
        .catch(function (error) {
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
    GetEmployees() {
      axios
        .get(`/company/dashboard/employees`, {
          headers: {
            Authorization: `Bearer ${cookie.get("CompanyToken")}`,
          },
        })
        .then((res) => {
          this.Employees = res.data.data.User;
        })
        .catch(function (error) {
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
    GetInvitations() {
      axios
        .get(`/company/dashboard/invitations`, {
          headers: {
            Authorization: `Bearer ${cookie.get("CompanyToken")}`,
          },
        })
        .then((res) => {
          this.Invitations = res.data.data.Invitation;
        })
        .catch(function (error) {
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
    FilterSearch() {
      if (!this.Search.trim()) {
        this.GetEmployees();
        return;
      }
      var self = this;
      this.$store.dispatch("SETSpinner", true);
      axios
        .get(`/company/dashboard/employees?search=${this.Search}`, {
          headers: {
            Authorization: `Bearer ${cookie.get("CompanyToken")}`,
          },
        })
        .then((res) => {
          this.Employees = res.data.data.User;
          this.$store.dispatch("SETSpinner", false);
        })
        .catch(function (error) {
          self.$store.dispatch("SETSpinner", false);
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
    FilterByCity(e) {
      var self = this;
      this.$store.dispatch("SETSpinner", true);
      axios
        .get(`/company/dashboard/employees?city_id=${event.target.value}`, {
          headers: {
            Authorization: `Bearer ${cookie.get("CompanyToken")}`,
          },
        })
        .then((res) => {
          this.Employees = res.data.data.User;
          this.$store.dispatch("SETSpinner", false);
        })
        .catch(function (error) {
          self.$store.dispatch("SETSpinner", false);
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
  },
  mounted() {
    this.GetEmployees();
    this.GetInvitations();
    this.$store.dispatch("getCities").then((r) => {
      this.cities = this.$store.state.cities;
    });
  },
};
</script>
